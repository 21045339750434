<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-store" textTitle="Orden de inventario">
            <template #contentCard>
              <v-form v-model="validForm" @input="inputForm">
                <v-row class="mt-3">
                  <v-col v-if="!!inventoryOrderId" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Fecha</label>
                      <div class="v-input v-text-field input">
                        <span>{{ currentElement.createdAt }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="!!inventoryOrderId" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Folio</label>
                      <div class="v-input v-text-field input">
                        <span>{{ currentElement.folio }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-3">

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Tipo<span
                          class="required-field-form">*</span></label>
                      <v-select
                          v-model="currentElement.type"
                          id="phone"
                          :items="types"
                          :readonly="!!inventoryOrderId"
                          :rules="required"
                          @change="resetStoreValue"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona un producto"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="street">Comentario (Colocar nombre de paciente y/o personal)<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.comments"
                          :readonly="!!inventoryOrderId"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un comentario (Colocar nombre de paciente y/o personal)"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="currentElement.type === 'OUT_WAREHOUSE' || currentElement.type === 'MOVEMENT_WAREHOUSE'"
                         cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Tienda de salida<span
                          class="required-field-form">*</span></label>
                      <v-select
                          @change="changeStoreOut"
                          v-model="currentElement.storeOutId"
                          id="phone"
                          :items="stores"
                          :readonly="!!inventoryOrderId"
                          :rules="currentElement.type === 'OUT_WAREHOUSE' || currentElement.type === 'MOVEMENT_WAREHOUSE' ? required : false"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona una tienda de entrada"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="currentElement.type === 'IN_WAREHOUSE' || currentElement.type === 'MOVEMENT_WAREHOUSE'"
                         cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Tienda de entrada<span
                          class="required-field-form">*</span></label>
                      <v-select
                          @change="changeStoreOut"
                          v-model="currentElement.storeInId"
                          id="phone"
                          :items="stores"
                          :readonly="!!inventoryOrderId"
                          :rules="currentElement.type === 'IN_WAREHOUSE' || currentElement.type === 'MOVEMENT_WAREHOUSE' ? required : false"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona una tienda de entrada"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </title-info-card>
        </v-col>

        <v-col v-if="!inventoryOrderId" class="mt-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-store" textTitle="Lotes">
            <template #contentCard>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-data-table
                      :headers="headers"
                      :items="currentElement.lots"
                      :search="search"
                      :mobile-breakpoint="200"
                      item-key="uuid"
                      sort-by="calories"
                      :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
                      class="elevation-1"
                      @click:header="headerClicked"
                  >
                    <template v-slot:top>
                      <div>
                        <v-toolbar
                            flat
                        >
                          <v-toolbar-title>Configuración de lotes</v-toolbar-title>
                          <v-divider
                              class="mx-4"
                              inset
                              vertical
                          ></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                              v-model="dialog"
                              max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  rounded
                                  :disabled="!hasAccess('/store-stocks', 'POST')"
                                  class="btn-active ma-2"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                Agregar
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <div class="mb-5" style="display: flex; justify-content: center;">
                                    <h3 style="color: var(--v-hideLabel-base)">{{ text }}</h3>
                                  </div>
                                  <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="phone">Producto<span
                                            class="required-field-form">*</span></label>
                                        <v-autocomplete
                                            v-model="productId"
                                            :items="products"
                                            @change="getProductUnit"
                                            :rules="required"
                                            :return-object="true"
                                            item-text="name"
                                            hide-details
                                            outlined
                                            clearable
                                            color="backofficeInput"
                                            placeholder="Selecciona un producto"
                                        ></v-autocomplete>
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Presentación<span
                                            class="required-field-form">*</span></label>
                                        <v-select
                                            v-model="editedItem.productUnitId"
                                            id="street"
                                            :items="productUnits"
                                            @change="getExpirationProductByStoreIdAndUnitProduct"
                                            :rules="required"
                                            :return-object="true"
                                            :item-text="getUnitName"
                                            hide-details
                                            outlined
                                            clearable
                                            color="backofficeInput"
                                            placeholder="Selecciona una presentación"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Cantidad<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model.number="editedItem.quantity"
                                            id="street"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una cantidad"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col
                                        v-if="currentElement.type !== 'MOVEMENT_WAREHOUSE' && currentElement.type !== 'OUT_WAREHOUSE'"
                                        cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div v-if="productId && productId?.perishableProduct">
                                        <label class="input-label" for="street">Fecha de caducidad<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model="editedItem.expirationDate"
                                            id="street"
                                            type="date"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una fecha de caducidad"
                                        />
                                      </div>
                                      <div v-else>
                                        <div class="v-input v-text-field input">
                                          <span>Sin fecha de caducidad</span>
                                        </div>
                                      </div>
                                    </v-col>
                                    <v-col v-else cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Disponibles<span
                                            class="required-field-form">*</span>
                                          {{ editedItem?.productId }}</label>
                                        <v-select
                                            v-if="isPerishableProduct"
                                            v-model="editedItem.expirationDate"
                                            id="street"
                                            :rules="required"
                                            :items="expirationDates"
                                            :return-object="true"
                                            :item-text="getExpirationText"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una fecha de caducidad"
                                        />
                                        <div v-else class="v-input v-text-field input">

                                          <span
                                              v-if="editedItem.expirationDate">{{ getExpirationText(editedItem.expirationDate) }}</span>
                                          <span v-else>Sin disponibilidad</span>

                                        </div>
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Número de lote<span
                                            class="required-field-form"></span></label>
                                        <v-text-field
                                            v-model="editedItem.lotNumber"
                                            id="street"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe un número de lote"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Fecha de producción<span
                                            class="required-field-form"></span></label>
                                        <v-text-field
                                            v-model="editedItem.productionDate"
                                            id="street"
                                            type="date"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una fecha de producción"
                                        />
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeForm"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn
                                    @click="saveUnit"
                                    :disabled="!disabledDialogForm"
                                    color="blue darken-1"
                                    text
                                >
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                        <div class="mx-4 mb-4">
                          <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              outlined
                              hide-details
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="{item, index}">
                      <tr>
                        <td>
                          <span>{{ item.name }}</span>
                        </td>
                        <td v-if="item.productUnitId && item.productUnitId.unit && item.productUnitId.unit.name">
                          <span>{{ item.productUnitId.unit.name }}</span>
                        </td>
                        <td v-else>
                          <span>'-'</span>
                        </td>
                        <td>
                          <span v-if="item.lotNumber">{{ item.lotNumber }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="item.productionDate">{{ item.productionDate }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="item.expirationDate">{{ item.expirationDate }}</span>

                          <span v-else>-</span>
                        </td>

                        <td>
                          <span>{{ item.quantity }}</span>
                        </td>

                        <td>
                          <v-icon v-if="!inventoryOrderId"
                                  small
                                  @click="deleteInventory(item, index)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </title-info-card>
        </v-col>
        <v-col v-else class="mt-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-table-large" textTitle="Lotes">
            <template #contentCard>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-data-table
                      :headers="headerService"
                      :items="currentElement.lots"
                      :search="search"
                      item-key="uuid"
                      :mobile-breakpoint="200"
                      sort-by="calories"
                      class="elevation-1"
                      @click:header="headerClicked"
                      :footer-props="{
    'items-per-page-options': [10, 20, 30, 100, 1000 ]
  }"
                  >
                    <template v-slot:top>
                      <div>
                        <v-toolbar
                            flat
                        >
                          <v-toolbar-title>Configuración de lotes</v-toolbar-title>
                          <v-divider
                              class="mx-4"
                              inset
                              vertical
                          ></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                              v-model="dialog"
                              max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  disabled
                                  rounded
                                  class="btn-active ma-2"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                Agregar
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="phone">Producto<span
                                            class="required-field-form">*</span></label>
                                        <v-select
                                            v-model="productId"
                                            id="phone"
                                            :items="products"
                                            @change="getProductUnit"
                                            :rules="required"
                                            :return-object="true"
                                            item-text="name"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Selecciona un producto"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Presentación<span
                                            class="required-field-form">*</span></label>
                                        <v-select
                                            v-model="editedItem.productUnitId"
                                            id="street"
                                            :items="productUnits"
                                            :rules="required"
                                            :return-object="true"
                                            item-text="unit.name"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Selecciona una presentación"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Cantidad<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model.number="editedItem.quantity"
                                            id="street"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una cantidad"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Número de lote<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model="editedItem.lotNumber"
                                            id="street"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe un número de lote"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Fecha de producción<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model="editedItem.productionDate"
                                            id="street"
                                            type="date"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una fecha de producción"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                      <div>
                                        <label class="input-label" for="street">Fecha de caducidad<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model="editedItem.expirationDate"
                                            id="street"
                                            type="date"
                                            :rules="required"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Escribe una fecha de caducidad"
                                        />
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeForm"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn
                                    @click="saveUnit"
                                    :disabled="!disabledDialogForm"
                                    color="blue darken-1"
                                    text
                                >
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                        <div class="mx-4 mb-4">
                          <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              outlined
                              hide-details
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="{item}">
                      <tr>
                        <td>
                          <span>{{ item.productUnit?.product?.name }}</span>
                        </td>
                        <td>
                          <span>{{ item.productUnit?.unit?.name }}</span>
                        </td>
                        <td>
                          <span>{{ item?.lotNumber || "-" }}</span>
                        </td>
                        <td>
                          <span v-if="item.productionDate">{{ item?.productionDate }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="item.expirationDate">{{ formatTime(item.expirationDate) }}</span>
                          <span v-else>-</span>
                        </td>

                        <td>
                          <span>{{ item?.quantity }}</span>
                        </td>

                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </title-info-card>
        </v-col>



        <v-col v-if="currentElement.uuid && hasAccess('/inventory-order-status', 'GET')" class="mt-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-format-list-checks" textTitle="Registros">
            <template #contentCard>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-data-table
                      :headers="headerLogsService"
                      :items="logs"
                      :search="searchRegister"
                      item-key="uuid"
                      :mobile-breakpoint="200"
                      sort-by="calories"
                      class="elevation-1"
                      @click:header="headerClicked"
                      :footer-props="{
    'items-per-page-options': [10, 20, 30, 100, 1000 ]
  }"
                  >
                    <template v-slot:top>
                      <div>

                        <div class="mx-4 mb-4">
                          <v-text-field
                              v-model="searchRegister"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              outlined
                              hide-details
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="{item}">
                      <tr>
                        <td>
                          <span>{{ item.createdAt }}</span>
                        </td>
                        <td>
                          <span v-if="item.employee">{{ `${item?.employee?.name} ${item?.employee?.lastName}` }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div  v-on="on" class="border-circle" :style="{'background-color': availableStatus[item.status].color}"></div>
                            </template>
                            <span>{{ availableStatus[item.status].title }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <span>{{ availableStatus[item.status].title }}</span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </title-info-card>
        </v-col>
      </v-row>
    </v-col>
    <ActionPopUp
        :dialog="dialogInventory"
        :iteName="'Elemento'"
        :type="type"
        :message="message"
        @cancelAction="dialogInventory = false"
        @closeAction="closeAction"
    ></ActionPopUp>
    <ActionPopUp
        :dialog="deleteInventoryDialog"
        :iteName="'Elemento'"
        :type="typeDelete"
        @cancelAction="deleteInventoryDialog = false"
        @closeAction="closeActionEdit"
        @deleteAction="deleteAction"
    ></ActionPopUp>

    <ActionPopUp
        :dialog="openConfirmationChangeStore"
        :iteName="'Confirmar'"
        :type="'info'"
        :subtitleInfo="'Si deseas cambiar de tienda se eliminaran todos los lotes asignados'"
        :titleInfo=getStoreOutInfo()
        @cancelAction="openConfirmationChangeStore = false"
        @closeAction="closeActionChangeStore"
        @infoAction="confirmChangeStore"
    ></ActionPopUp>

  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "@/utils/validationRule";
import customStore from "../../services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";
import moment from "moment";
import customStoreService from "../../services/customStore";
import moduleMixin from "../../mixins/moduleMixin";

export default {
  name: "StoreStockForm",
  mixins:[moduleMixin],
  components: {ActionPopUp, TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      default: () => []
    },
    stores: {
      type: Array,
      default: () => []
    },
    logs: {
      type: Array,
      default: () => []
    },
    inventoryOrderId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      availableStatus:{
        'REVIEWED': {title:'Revisado', color:'#FF8F00'},
        'APPROVED':{title:'Aprobado', color:'#1B5E20'},
        'ADJUSTMENT':{title:'Ajuste', color:'#E65100'},
        'CANCELLED':{title:'Cancelado', color:'#B71C1C'},
        'CREATED':{title:'Creado', color:'#0D47A1'}
      },
      openConfirmationChangeStore: false,
      isPerishableProduct: false,
      openSnackBar: false,
      text: '',
      headerService: [
        {
          text: 'Producto',
          sortable: true,
          value: 'productUnit.product.name',
        },
        {
          text: 'Presentación',
          sortable: true,
          value: 'productUnit.unit.name',
        },
        {
          text: 'Número de lote',
          sortable: true,
          value: 'lotNumber',
        },
        {
          text: 'Fecha de producción',
          sortable: true,
          value: 'productionDate',
        },
        {
          text: 'Fecha de Expiración',
          sortable: true,
          value: 'expirationDate',
        },
        {
          text: 'Total',
          sortable: true,
          value: 'quantity',
        },
      ],
      types: [{uuid: 'MOVEMENT_WAREHOUSE', name: 'Movimiento Almacén'}, {
        uuid: 'IN_WAREHOUSE',
        name: 'Entrada Almacén'
      }, {uuid: 'OUT_WAREHOUSE', name: 'Salida Almacén'}],
      deleteInventoryDialog: false,
      typeDelete: 'delete',
      type: 'error',
      items: [],
      required,
      dialog: false,
      search: '',
      searchRegister:'',
      productUnits: [],
      validForm: false,
      productId: {},
      editedItem: {
        productUnitId: {},
        lotNumber: '',
        productionDate: '',
        expirationDate: '',
        quantity: null
      },
      headers: [
        {
          text: 'Producto',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Presentación',
          sortable: true,
          value: 'productUnitId.unit.name',
        },
        {
          text: 'Número de lote',
          sortable: true,
          value: 'lotNumber',
        },
        {
          text: 'Fecha de producción',
          sortable: true,
          value: 'productionDate',
        },
        {
          text: 'Fecha de Expiración',
          sortable: true,
          value: 'expirationDate',
        },
        {
          text: 'Total',
          sortable: true,
          value: 'quantity',
        },
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      headerLogsService:[
        {
          text: 'Fecha de modificación',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: 'Empleado',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Estatus',
          sortable: true,
          value: 'status',
        },
        {
          text: '',
          sortable: true,
          value: 'status',
        },
      ],
      editedIndex: -1,
      message: '',
      dialogInventory: false,
      selectedRow: {},
      expirationDates: [],
      auxStoreOut: '',
      auxStoreIn: ''
    }
  },
  watch: {
    'currentElement.storeOutId'(newVal, oldVal) {
      if (this.currentElement.lots.length) {
        this.auxStoreOut = oldVal
      }
    },
    'currentElement.storeInId'(newVal, oldVal) {
      if (this.currentElement.lots.length) {
        this.auxStoreIn = oldVal
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Lote' : 'Editar Lote'
    },
    disabledDialogForm() {
      if (this.productId && !this.productId?.perishableProduct) {
        return this.editedItem.productUnitId && this.editedItem.quantity && this.productId
      }
      return this.editedItem.productUnitId && this.editedItem.expirationDate && this.editedItem.quantity && this.productId
    }
  },
  methods: {
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    validExpirationQuantity() {
      return this.editedItem?.quantity > this.editedItem?.expirationDate?.stock;
    },
    saveUnit() {
      this.validExpirationQuantity()
      if (this.validExpirationQuantity()) {
        this.text = 'La cantidad ingresada supera la cantidad disponible'
        return
      }
      if (this.editedItem?.quantity) {
        delete this.productId.quantity
      }
      this.text = ''

      if (typeof this.editedItem.expirationDate === 'object') {
        this.editedItem.expirationDate = this.editedItem.expirationDate?.expirationDate
      }

      this.currentElement.lots.push({...this.editedItem, ...this.productId})
      this.closeForm()
    },
    deleteInventory(item, index) {
      this.selectedRow = item
      this.selectedRow.index = index
      this.deleteInventoryDialog = true
    },
    closeForm() {
      this.text = ''
      this.dialog = false
      this.productUnits = []
      this.productId = {}
      this.editedItem = {
        productUnitId: {},
        lotNumber: '',
        productionDate: '',
        expirationDate: '',
        quantity: 0
      }
    },
    async getProductUnit() {
      await this.getProductUnitByProductId()
      await this.getExpirationProductByStoreIdAndUnitProduct()
    },
    validadProductAvailable() {
      if (!this.productId?.perishableProduct) {
        this.isPerishableProduct = false
        if (this.expirationDates.length) {
          this.editedItem.expirationDate = this.expirationDates[0]
        }
      } else {
        this.isPerishableProduct = true
      }
    },
    async getProductUnitByProductId() {
      try {
        if (!this.productId) {
          this.productUnits = []
          return
        }
        const newParams = {
          productId: this.productId.uuid
        }
        const {data: dataUniProduct} = await customStore.getDataByModuleAndParam(`product-units`, '', 0, 100000, "", "", "Unit", newParams)
        this.productUnits = dataUniProduct
        if (this.productUnits?.length > 0) {
          this.editedItem.productUnitId = this.productUnits[0]
        }
      } catch (errorMessage) {
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogInventory = true
      }
    },
    closeAction() {
      this.dialogInventory = false
      this.message = ''
    },
    closeActionEdit() {
      this.selectedRow = {}
      this.deleteInventoryDialog = false
    },
    async deleteAction() {
      this.currentElement.lots.splice(this.selectedRow.index, 1)
      this.closeActionEdit()
    },
    closeActionChangeStore() {
      this.openConfirmationChangeStore = false
      if (this.currentElement.type === 'IN_WAREHOUSE') {
        this.currentElement.storeInId = this.auxStoreIn
      }else {
        this.currentElement.storeOutId = this.auxStoreOut
      }
    },
    confirmChangeStore() {
      this.currentElement.lots = []
      this.openConfirmationChangeStore = false
      this.auxStoreOut = ''
      this.auxStoreIn = ''
    },
    resetStoreValue() {
      if (this.currentElement.type === 'IN_WAREHOUSE') {
        this.currentElement.storeOutId = null
        this.auxStoreOut = null
      } else if (this.currentElement.type === 'OUT_WAREHOUSE') {
        this.currentElement.storeInId = null
        this.auxStoreIn = null
      }
    },
    formatTime(time) {
      if (!time) return "-"
      return moment(time).format("YYYY-MM-DD")
    },
    async getExpirationProductByStoreIdAndUnitProduct() {
      this.expirationDates = []
      this.editedItem.expirationDate = null
      if (!this.currentElement?.storeOutId) return
      if (!this.productId?.uuid || !this.editedItem?.productUnitId?.uuid) return
      try {
        const data = {
          productId: this.productId.uuid,
          productUnitId: this.editedItem.productUnitId.uuid
        }
        this.expirationDates = await customStoreService.getDataByModuleAndIdAndParamWithOutPage('store-stocks', this.currentElement.storeOutId, data)
        this.validadProductAvailable()
      } catch (errorMessage) {
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogInventory = true
      }
    },
    getExpirationText(event) {
      if (!event?.expirationDate) return `(${event?.stock ? event?.stock : 0})`
      return `${moment(event.expirationDate).format('YYYY-MM-DD')} - (${event.stock})`
    },
    getUnitName(event) {
      return `${event.unit.name} - (${event.quantity})`
    },
    getStoreOutInfo() {
      if (this.currentElement.type === 'IN_WAREHOUSE') {
        if (!this.auxStoreIn) return
        const store = this.stores.find((item) => item.uuid === this.auxStoreIn)
        return `Ya hay lotes asignados a la tienda ${store.name}`
      }else {
        if (!this.auxStoreOut) return
        const store = this.stores.find((item) => item.uuid === this.auxStoreOut)
        return `Ya hay lotes asignados a la tienda ${store.name}`
      }
    },
    changeStoreOut() {
      if (this.currentElement.lots.length) {
        this.openConfirmationChangeStore = true
      }
    },
    headerClicked(evtn) {
      console.log(evtn, 'click')
    }
  }
}
</script>

<style scoped>
.read-only-text {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}

.read-only-text-child {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
</style>
