const patterns = {
    mail: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
    pass: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/,
    name: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/,
    phone: /^(?!.*(\d)\1{9})\d{8,}$/,
    number: /^[0-9]*$/,
    code: /^[0-9]{6}$/,
    birthday: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
    rfc: /^([A-ZÑ&]{3,4})(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])([A-Z\d]{2})(\d|[A])$/
};

export const passRules = [
    (v) => !!v || "El campo es requerido",
    (v) => v.length >= 8 || "La contraseña debe ser mayor a 8 carácteres",
    (v) =>
        patterns.pass.test(v) ||
        "La contraseña debe tener al menos una letra mayúscula, una minúscula y un número",
];

export const emailRules = [
    (v) => !!v || "Correo electrónico requerido",
    (v) => patterns.mail.test(v) || "Correo electrónico requerido",
];
export const rfcRules = [
    (v) => !!v || "RFC requerido",
    (v) => patterns.rfc.test(v) || "RFC requerido",
];
export const nameRules = [
    (v) => patterns.name.test(v) || "No es un nombre válido",
    (v) => !v || v.length >= 2 || "El nombre debe ser mayor a 2 carácteres",
    (v) => !v || v.length <= 30 || "El nombre debe ser menor a 30 carácteres",
];
export const surNameRules = [
    (v) => !v || patterns.name.test(v) || "No es un apellido válido",
    (v) => !v || v.length >= 2 || "El apellido debe ser mayor a 2 carácteres",
    (v) => !v || v.length <= 30 || "El apellido debe ser menor a 30 carácteres",
];
export const birthday = [
    (v) => patterns.birthday.test(v) || "No es una fecha válido",
    (v) => !!v || "El campo es requerido",
];
export const phoneRules = [
    (v) => !!v || "El campo es requerido",
    // (v) => !v || v.length === 10 || i18n.t("validation.phone"),
    // (v) => !v || patterns.phone.test(v) || i18n.t("validation.invalidPhone")
];

export const required = [(v) => !!v || "El campo es requerido"];

export const numberRules = [
    (v) => /^[0-9]*$/.test(v) || "Número invalido",
    (v) => (v && v.length <= 30) || "Número invalido",
];

export const rules = {
    required,
    emailRules,
    phoneRules,
    numberRules,
    birthday,
};
