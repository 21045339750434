<template>
  <v-container max-width="1200">
    <Breadcrumbs v-if="!isWizard" :items="breadcrumbsItems"/>
    <top-info-action
        :show-right-item="true"
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validForm && hasAccess('/products', 'POST')"
        principal-btn-title="Guardar"
        :secondary-btn-title="currentElement.uuid ? 'Regresar' : 'Cancelar'"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    ></top-info-action>
    <div v-if="loaded">
    <!--      aqui va el formulario-->
      <products-form :current-element="currentElement" :product-categories="productCategories" :units="units"  @validFormInput="validFormInput"></products-form>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="validForm && hasAccess('/products', 'POST')"
          principal-btn-title="Guardar"
          :secondary-btn-title="currentElement.uuid ? 'Regresar' : 'Cancelar'"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Producto'"
        :message="message"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TopInfoAction from "@/components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import ProductsForm from "@/components/products/ProductsForm.vue";
import customStore from "@/services/customStore";
import moduleMixin from "../mixins/moduleMixin";
export default {
  name: "CRUDProducts",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "products"},
  },
  components: {
    ProductsForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Productos",
          disabled: false,
          path: "/products",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Producto`,
          disabled: true,
          path: "",
        },
      ],
      currentElement: {
        name:'',
        code:'',
        providerCode:'',
        productCategoryId:'',
        unitId:'',
        perishableProduct:false
      },
      productCategories:[],
      units:[],
      module: "products",
      titleCreate: "Crear nuevo producto",
      titleEdit: "Editar producto",
      redirectAfterSave: false,
      crudAction:'edit',
      pathName:'CrudProducts'
    };
  },
  methods: {
    async loadData() {
      try {
       const {data: dataCategory} = await customStore.getDataByModule('product-categories', '', 0, 300)
        const {data: dataUnit} = await customStore.getDataByModule('units', '', 0, 1000)
        if(dataCategory) {
          this.productCategories = dataCategory
        }
        if (dataUnit) {
          this.units = dataUnit
        }
      }catch(error){
        console.log(error)
      }
      if (this.$route?.params?.crudAction === "create") {
        this.currentElement.priceSell = null
        this.currentElement.priceBuy = null
        this.currentElement.quantity = 1
      }
    },
    async beforeFillData() {},
    validFormInput(e) {
      this.validForm = e;
    },
    async afterSend() {},
    beforeSend() {

      if (!this.currentElement?.code) {
        delete this.currentElement.code
      }

      if (!this.currentElement?.providerCode) {
        delete this.currentElement.providerCode
      }

      console.log(this.currentElement, 'currentElement')

    },
  },
};
</script>

<style scoped></style>
