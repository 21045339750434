<template>
  <div>
    <div class="display-item-justify">
      <div class="display-align-center">
        <v-icon :color="colorIcon" size="35">{{ icon }}</v-icon>
        <h3 class="ml-2 title-content-card">{{ textTitle }}</h3>
      </div>
      <div v-if="!hidePrint" class="cursor-pointer-action" @click="setShowContent">
        <span class="hide-content-card">{{ showContentCard ? 'Ocultar' : 'Mostrar' }}</span>
        <v-icon v-if="showContentCard" color="hideLabel">mdi-chevron-up</v-icon>
        <v-icon v-else color="hideLabel">mdi-chevron-down</v-icon>
      </div>
    </div>
    <div v-show="showContentCard">
      <slot name="contentCard">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleInfoCard",
  props: {
    hidePrint: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: ''
    },
    colorIcon: {
      type: String,
      default: 'title1'
    },
    textTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showContentCard: true
    }
  },
  methods: {
    setShowContent() {
      this.showContentCard = !this.showContentCard
    }
  }
}
</script>

<style lang="scss" scoped>
.title-content-card {
  font-family: 'Montserrat-Regular';
  color: var(--v-title1-base);
}

.hide-content-card {
  color: var(--v-hideLabel-base);
  font-size: 12px;
  font-family: 'Montserrat-Medium';
}
</style>
