import Vue from 'vue'
import VueI18n from 'vue-i18n'
import customStoreService from "../services/customStore";

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'es',
    messages:{}
})

const verifyLoadedLocale = () => {
    const cachedMessages = localStorage.getItem('localeMessages')
    const messages = JSON.parse(cachedMessages)
    if (messages) {
        Object.keys(messages).forEach(locale => {
            i18n.setLocaleMessage(locale,messages[locale])
        })
        i18n.locale = 'es'
    }
    return !!cachedMessages
}

export const loadLocaleMessages = async () => {
    if (verifyLoadedLocale()) {
        return
    }
    try {
        const response = await customStoreService.getDataByModuleSimple('public/i18n')
        Object.keys(response).forEach(locale => {
            i18n.setLocaleMessage(locale, response[locale])
        })
        localStorage.setItem('localeMessages', JSON.stringify(response))
        i18n.locale = 'es'
    }catch(error){
        console.log(error)
    }
}

verifyLoadedLocale()

export default i18n