import Axios from "axios";
import {urlFull} from "./config";

const BASE_LOGIN_URL = "/stores";

const storesService = {
    postStore(data) {
        return Axios.post(urlFull(BASE_LOGIN_URL), data).then((res) => res.data);
    },
    getStoreById(data) {
        return Axios.get(urlFull(`${BASE_LOGIN_URL}/${data}`)).then(
            (res) => res.data
        );
    },
    getStoreByEmployeeId(data) {
        return Axios.get(urlFull(`/store-employees/${data.uuid}`)).then(
            (res) => res.data
        );
    },
    updateStore(data) {
        return Axios.post(urlFull(`${BASE_LOGIN_URL}`), data).then(
            (res) => res.data
        );
    },
    updateStoreEmployee(userId, data) {
        return Axios.post(urlFull(`/store-employees/${userId}`), data).then(
            (res) => res.data
        );
    }
};

export default storesService;
