<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :show-right-item="true"
        :title="showTitle"
        :valid-btn="!!(validForm && currentElement.lots.length && hasAccess('/store-stocks', 'POST')) && $route.params.crudAction !== 'edit'"
        secondary-btn-title="Regresar"
        principal-btn-title="Guardar"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <store-stock-form :current-element="currentElement"
                        :products="products"
                        :stores="stores"
                        :inventory-order-id="$route?.params?.id"
                        :logs="logs"
                        @validFormInput="validFormInput"
                        @getLotsData="getLotsByInventoryOrderId"
      ></store-stock-form>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="!!(validForm && currentElement.lots.length && hasAccess('/store-stocks', 'POST')) && $route.params.crudAction !== 'edit'"
          secondary-btn-title="Regresar"
          principal-btn-title="Guardar"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Orden de inventario'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import customStore from "../services/customStore";
import StoreStockForm from "../components/storeStock/StoreStockForm.vue";
import moduleMixin from "../mixins/moduleMixin";


export default {
  name: "CRUDStoreStock",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "store-stocks"},
  },
  components: {
    StoreStockForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Órdenes de inventario",
          disabled: false,
          path: "/store-stocks",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nueva"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } orden de inventario`,
          disabled: true,
          path: "/inventory-stores",
        },
      ],
      currentElement: {
        storeOutId: null,
        storeInId: null,
        type: null,
        comments: null,
        lots: []
      },
      products: [],
      stores: [],
      storeName: '',
      module: "inventory-orders",
      titleCreate: "Crear orden de inventario",
      titleEdit: `Editar orden de inventario`,
      copyCurrentElementLots: [],
      logs: []
    };
  },
  computed: {},

  methods: {
    beforeSend() {
      if (this.$route.params?.crudAction &&
          this.$route.params?.crudAction === "create") {
        let newLots = [...this.currentElement.lots]
        this.copyCurrentElementLots = [...this.currentElement.lots]
        newLots = newLots.map((item) => ({
          productUnitId: item.productUnitId.uuid,
          lotNumber: item.lotNumber,
          productionDate: item.productionDate,
          expirationDate: item.expirationDate,
          quantity: item.quantity
        }))
        this.currentElement.lots = newLots
      }
    },
    validFormInput(e) {
      this.validForm = e;
    },
    async loadData() {
      const {data: products} = await customStore.getDataByModule('products', '', 0, 10000)
      this.products = products
      const {data: stores} = await customStore.getDataByModule('stores', '', 0, 10000)
      this.stores = stores
    },
    async beforeFillData() {
      await this.getLotsByInventoryOrderId()
    },
    async getLotsByInventoryOrderId() {
      if (!this.$route?.params?.id) {
        return
      }
      const newParams = {
        inventoryOrderId: this.$route?.params?.id
      }
      const {data: lotsInfo} = await customStore.getDataByModuleAndParam(`lots`, '', 0, 10000, "", "", "", newParams)
      this.currentElement.lots = lotsInfo || []
      if (!this.hasAccess('/inventory-order-status', 'GET')) return
      const {data: logs} = await customStore.getDataByModule('inventory-order-status', '', 0, 10000)
      this.logs = logs.filter((item) => item.inventoryOrderId === this.$route?.params?.id)
    },
    errorSave() {
      this.currentElement.lots = this.copyCurrentElementLots
    }
  },
};
</script>

<style scoped></style>
