<template>
  <v-container max-width="1200">
    <Breadcrumbs v-if="!isWizard" :items="breadcrumbsItems"/>
    <top-info-action
        :show-right-item="true"
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validForm && employeeRoles.length >= 1 && employeeStores.length >= 1 && hasAccess('/employees', 'POST')"
        principal-btn-title="Guardar"
        secondary-btn-title="Cancelar"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    ></top-info-action>
    <div v-if="loaded">
      <form-employee
          v-if="loaded"
          :available-roles="availableRoles"
          :current-element="currentElement"
          :hide-field="$route.params.crudAction === 'edit' ? true : false"
          :roles-by-employee="employeeRoles"
          :stores-by-employee="employeeStores"
          @selectImage="selectImage"
          @selectedItem="selectedItem"
          @selectedStore="selectedStore"
          @validFormInput="validFormInput"
      ></form-employee>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="validForm && employeeRoles.length >= 1 && employeeStores.length >= 1 && hasAccess('/employees', 'POST')"
          principal-btn-title="Guardar"
          secondary-btn-title="Cancelar"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Empleado'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TopInfoAction from "@/components/TopInfoAction.vue";
import FormEmployee from "@/components/employee/FormEmployee.vue";
import employeeService from "@/services/employeeService";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import storesService from "@/services/storesService";
import moduleMixin from "../mixins/moduleMixin";

export default {
  name: "CRUDemployee",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "employees"},
  },
  components: {
    ActionPopUp,
    SkeletonLoaded,
    FormEmployee,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Empleados",
          disabled: false,
          path: "/employees",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Empleado`,
          disabled: true,
          path: "",
        },
      ],
      currentElement: {
        photoImageName: '',
        name: "",
        lastName: "",
        email: "",
        password: "",
        birthday: "",
        phone: "",
        active: false,
        roles: [],
        address: {
          street: "",
          city: "",
          state: "",
          zip: "",
        },
      },
      availableRoles: [],
      employeeRoles: [],
      employeeStores: [],
      module: "employees",
      titleCreate: "Crear nuevo empleado",
      titleEdit: "Editar empleado",
    };
  },
  methods: {
    async loadData() {
      await this.getRoles();
    },
    async beforeFillData() {
      await this.getRolesByEmployeeId();
      await this.getEmployeeStores();
    },
    selectedItem(e) {
      const index = this.employeeRoles.findIndex(
          (item) => item.uuid === e.uuid
      );
      if (index !== -1) {
        this.employeeRoles.splice(index, 1);
      } else {
        this.employeeRoles.push(e);
      }
    },
    validFormInput(e) {
      this.validForm = e;
    },
    async afterSend() {
      await this.employeeRoleChanged();
      await this.employeeStoreChanged()
    },
    beforeSend() {
      this.currentElement.active = true;
    },
    async getRoles() {
      try {
       const {data} = await employeeService.getRoles();
        this.availableRoles = data
      } catch {
        this.type = "error";
        this.dialog = true;
      }
    },
    async getEmployeeStores() {
      try {
        const res = await storesService.getStoreByEmployeeId(this.currentElement);
        if (res) {
          this.employeeStores = res
        }
      } catch {
        this.type = "error";
        this.dialog = true;
      }
    },
    async getRolesByEmployeeId() {
      try {
        this.employeeRoles = await employeeService.getRolesByEmployeeId(
            this.currentElement
        );
      } catch {
        this.type = "error";
        this.dialog = true;
      }
    },
    async employeeRoleChanged() {
      await employeeService.updateRoleEmployee(
          this.responseElement.uuid,
          this.employeeRoles
      );
    },
    async employeeStoreChanged() {
      await storesService.updateStoreEmployee(
          this.responseElement.uuid,
          this.employeeStores
      );
    },
    selectImage(imageName) {
      this.currentElement.photoImageName = imageName;
    },
    selectedStore(selectedStore) {
      const index = this.employeeStores.findIndex(
          (item) => item.uuid === selectedStore.uuid
      );
      if (index !== -1) {
        this.employeeStores.splice(index, 1);
      } else {
        this.employeeStores.push(selectedStore);
      }
    }
  },
};
</script>

<style scoped></style>
