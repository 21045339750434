<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col class="mt-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <title-info-card icon="mdi-store" :textTitle="`Inventario por tienda ${completeStore}`">
              <template #contentCard>
                <v-row class="mt-5">
                  <v-col v-if="hasAccess('/inventory/download', 'GET')" cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <download-btn module="/inventory/download" titleBtn="Inventario" fileDownloadName="inventario"
                                  :store-id="[currentElement.storeId]"></download-btn>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-dialog
                        v-model="dialogFixInventory"
                        max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="hasAccess('/store-stocks/upload', 'POST')"
                            rounded
                            style="width: 100%"
                            class="btn-active v-btn-primary"
                            v-bind="attrs"
                            v-on="on"
                        >
                          Cuadrar inventario
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Cuadrar inventario de {{ completeStore }}</span>
                        </v-card-title>
                        <v-card-subtitle>
                          <span>
                          Esta acción eliminara el inventario actual y lo reestablecera como el archivo que se ingrese
                          </span>

                        </v-card-subtitle>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-file-input
                                    v-model="storeStockFile"
                                    prepend-icon="mdi-file-excel"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    label="File input"
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="closeUploadStoreStockDialog"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                              @click="uploadStoreStockFile"
                              color="blue darken-1"
                              text
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <inventory-store-by-store-resume
                        :store-id="currentElement.storeId"></inventory-store-by-store-resume>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <title-info-card icon="mdi-store" :textTitle="`Mínimos y máximos de la tienda ${completeStore}`">
              <template #contentCard>
                <v-row class="mt-5">
                  <v-col v-if="hasAccess('/inventory-stores/download', 'GET')" cols="12" lg="3" md="3" sm="6" xl="3"
                         xs="12">
                    <download-btn module="/inventory-stores/download" titleBtn="Mínimos y Máximos"
                                  fileDownloadName="minimo-maximo" :store-id="[currentElement.storeId]"></download-btn>
                  </v-col>
                  <v-dialog
                      v-model="dialogUploadInventoryStore"
                      max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="hasAccess('/inventory-stores/upload', 'POST')"
                          rounded
                          style="width: 100%"
                          class="btn-active v-btn-primary"
                          v-bind="attrs"
                          v-on="on"
                      >
                        Actualizar minimos y maximos
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Actualizar minimos y maximos</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                              <v-file-input
                                  v-model="inventoryStoreFile"
                                  prepend-icon="mdi-file-excel"
                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  label="File input"
                              ></v-file-input>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeUploadInventoryStoreDialog"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                            @click="uploadInventoryStoreFile"
                            color="blue darken-1"
                            text
                        >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table
                        :headers="headers"
                        :mobile-breakpoint="200"
                        :items="currentElement.inventoryStore"
                        :search="search"
                        item-key="uuid"
                        sort-by="calories"
                        class="elevation-1"
                        :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
                    >
                      <template v-slot:top>
                        <div>
                          <v-toolbar
                              flat
                          >
                            <v-toolbar-title>Configuración de productos</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                                v-model="dialog"
                                max-width="600px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    rounded
                                    class="btn-active ma-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!hasAccess('/inventory-stores', 'POST')"
                                >
                                  Agregar
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{
                                      editedItem?.uuid ? 'Editar Mínimo y Máximo' : 'Agregar Mínimo y Máximo'
                                    }}</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div>
                                          <label class="input-label" for="phone">Producto<span
                                              class="required-field-form">*</span></label>
                                          <v-select
                                              v-model="productId"
                                              id="phone"
                                              :items="currentElement.products"

                                              :rules="required"
                                              item-value="uuid"
                                              item-text="name"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Selecciona un producto"
                                          />
                                        </div>
                                      </v-col>
<!--                                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div>
                                          <label class="input-label" for="street">Presentaciones<span
                                              class="required-field-form">*</span></label>
                                          <v-select
                                              v-model="editedItem.productUnitId"
                                              id="street"
                                              :items="productUnits"
                                              :rules="required"
                                              item-value="uuid"
                                              item-text="unit.name"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Selecciona una presentación"
                                          />
                                        </div>
                                      </v-col>-->
                                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div>
                                          <label class="input-label" for="street">Cantidad mínima<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model.number="editedItem.minStock"
                                              id="street"
                                              :rules="required"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Escribe una cantidad mínima"
                                          />
                                        </div>
                                      </v-col>
                                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div>
                                          <label class="input-label" for="street">Cantidad máxima<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model.number="editedItem.maxStock"
                                              id="street"
                                              :rules="required"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Escribe una cantidad máxima"
                                          />
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeForm"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                      @click="saveUnit"
                                      :disabled="!disabledDialogForm"
                                      color="blue darken-1"
                                      text
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                          <div class="mx-4 mb-4">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                single-line
                                outlined
                                hide-details
                            ></v-text-field>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item="{item}">
                        <tr>
                          <td>
                            <span>{{ item.product.providerCode || item?.product?.code  }}</span>
                          </td>
                          <td>
                            <span>{{ item.product.name }}</span>
                          </td>
                          <td>
                            <span>{{ item.product?.productCategory?.name }}</span>
                          </td>
                          <td>
                            <span>{{ item.minStock }}</span>
                          </td>
                          <td>
                            <span>{{ item.maxStock }}</span>
                          </td>
                          <td>
                            <v-icon
                                v-if="hasAccess('/inventory-stores', 'POST')"
                                small
                                @click="editInventory(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                                class="ml-2"
                                v-if="hasAccess('/inventory-stores', 'DELETE')"
                                small
                                @click="deleteInventory(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <ActionPopUp
        :dialog="dialogInventory"
        :iteName="'Elemento'"
        :type="type"
        :message="message"
        @cancelAction="dialogInventory = false"
        @closeAction="closeAction"
    ></ActionPopUp>
    <ActionPopUp
        :dialog="deleteInventoryDialog"
        :iteName="'Elemento'"
        :type="typeDelete"
        @cancelAction="deleteInventoryDialog = false"
        @closeAction="closeActionEdit"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";
import customStore from "../../services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";
import customStoreService from "../../services/customStore";
import InventoryStoreByStoreResume from "./InventoryStoreByStoreResume.vue";
import moduleMixin from "../../mixins/moduleMixin";
import DownloadBtn from "../downloadBtn.vue";

export default {
  name: "InventoryStoreForm",
  components: { DownloadBtn, InventoryStoreByStoreResume, ActionPopUp, TitleInfoCard},
  mixins: [moduleMixin],
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    storeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogFixInventory:false,
      dialogUploadInventoryStore:false,
      dialogInventoryStore: false,
      inventoryStoreFile: null,
      dialogStoreStock: false,
      storeStockFile: null,
      deleteInventoryDialog: false,
      typeDelete: 'delete',
      type: 'error',
      items: [],
      required,
      dialog: false,
      search: '',
      productUnits: [],
      validForm: false,
      productId: '',
      editedItem: {
        minStock: 0,
        maxStock: 0,
        /*productUnitId: ''*/
      },
      headers: [
        {
          text: 'Código',
          sortable: true,
          value: 'product.code',
        },
        {
          text: 'Producto',
          sortable: true,
          value: 'product.name',
        },
        {
          text: 'Categoría',
          sortable: true,
          value: 'product.productCategory.name',
        },
        {
          text: 'Mínimo',
          sortable: true,
          value: 'minStock',
        },
        {
          text: 'Máximo',
          sortable: true,
          value: 'maxStock',
        },
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      editedIndex: -1,
      message: '',
      dialogInventory: false,
      selectedRow: {}
    }
  },
  computed: {
    completeStore() {
      return this.storeName || ''
    },
    disabledDialogForm() {
      return this.editedItem.minStock && this.editedItem.maxStock && this.productId
    }
  },
  methods: {
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    async saveUnit() {
      const data = {
        productId: this.productId,
        storeId: this.currentElement.storeId,
        ...this.editedItem
      }
      try {
        await customStore.postDataByModule('inventory-stores', data)
        this.$emit('getInventoryData')
        this.closeForm()
      } catch (errorMessage) {
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogInventory = true
      }
    },

    deleteInventory(item) {
      this.selectedRow = item
      this.deleteInventoryDialog = true
    },
    async editInventory(item) {
      this.productId = item.productId
      this.editedItem.minStock = item.minStock
      this.editedItem.maxStock = item.maxStock
      this.editedItem.uuid = item.uuid
      /*await this.getProductUnit()*/
     /* if (this.productUnits?.length) {
        this.editedItem.productUnitId = this.productUnits.find((item) => item.productId === this.productId)
      }*/
      this.dialog = true
    },
    closeForm() {
      this.editedItem = {}
      this.dialog = false
      this.productUnits = []
      this.productId = ''
      this.editedItem = {
        minStock: null,
        maxStock: null,
      /*  productUnitId: null*/
      }
    },
    closeUploadInventoryStoreDialog() {
      this.dialogInventoryStore = false
      this.inventoryStoreFile = null
    },

    async uploadInventoryStoreFile() {
      const formData = new FormData();
      formData.append('file', this.inventoryStoreFile);
      formData.append('storeName', this.completeStore);

      await customStore.uploadInventoryStores(formData);

      this.inventoryStoreFile = null;
      this.dialogInventoryStore = false;
      window.location.reload();
    },
    closeUploadStoreStockDialog() {
      this.dialogStoreStock = false
      this.storeStockFile = null
    },
    async uploadStoreStockFile() {
      const formData = new FormData();
      formData.append('file', this.storeStockFile);
      formData.append('storeName', this.completeStore);

      await customStore.uploadStoreStocks(formData);

      this.storeStockFile = null;
      this.dialogStoreStock = false;
      window.location.reload();
    },
    async getProductUnit() {
      await this.getProductUnitByProductId()
    },
    async getProductUnitByProductId() {
      try {
        const newParams = {
          productId: this.productId
        }
        const {data: dataUniProduct} = await customStore.getDataByModuleAndParam(`product-units`, '', 0, 100000, "", "", "Unit", newParams)
        this.productUnits = dataUniProduct
      } catch (errorMessage) {
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogInventory = true
      }
    },
    closeAction() {
      this.dialogInventory = false
      this.message = ''
    },
    closeActionEdit() {
      this.selectedRow = {}
      this.deleteInventoryDialog = false
    },
    async deleteAction() {
      try {
        await customStoreService.deleteByModule('inventory-stores', this.selectedRow);
        this.$emit('getInventoryData')
        this.closeActionEdit()
      } catch (errorMessage) {
        this.closeActionEdit()
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogInventory = true
      }
    },
  }
}
</script>

<style scoped>

</style>
