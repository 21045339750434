<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="9" md="9" sm="6" xl="9" xs="12">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          />
        </v-col>
        <v-col v-if="hasAccess('/products/download', 'GET')" cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
          <download-btn module="/products/download" titleBtn="Productos" fileDownloadName="Producto"></download-btn>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
          <v-dialog
              v-model="dialogProductsUpload"
              max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-if="hasAccess('/products/upload', 'POST')"
                  rounded
                  style="width: 100%"
                  class="btn-active v-btn-primary"
                  v-bind="attrs"
                  v-on="on"
              >
                Agregar por excel
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Cuadrar inventario de {{ completeStore }}</span>
              </v-card-title>
              <v-card-subtitle>
                          <span>
                          Esta acción eliminara el inventario actual y lo reestablecera como el archivo que se ingrese
                          </span>

              </v-card-subtitle>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                      <v-file-input
                          v-model="productsFile"
                          prepend-icon="mdi-file-excel"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          label="File input"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeUploadForm"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    @click="uploadFile"
                    color="blue darken-1"
                    text
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
          :expanded.sync="expanded"
          :headers="headers"
          :items="dataSource"
          :loading="loaded"
          :mobile-breakpoint="200"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          :single-expand="singleExpand"
          class="mt-5"

          item-key="uuid"
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
          show-expand
      >
        <!--              <template v-slot:header="{ props }">
                        <thead class="v-data-table-header">
                        <tr>
                          <th class="text-start" v-for="header in props.headers" :key="header.value" @click="handleHeaderClick(header)">
                            <span>
                                {{ header.text }}
                            </span>
                          </th>
                        </tr>
                        </thead>
                      </template>-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="5" md="5" sm="5" xl="5">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-package-variant</v-icon>
                      <span class="ml-1">Información general</span>
                    </div>
                  </div>
                  <div class="px-6 mt-3">
                    <div cols="12" lg="12" md="12" sm="12" xl="12">
                      <div
                          class="display-item-column-center personal-information-text"
                      >
                        <span class="subtitle">Nombre: {{ item.name }}</span>
                        <span class="subtitle">Código local: {{ item.code || 'N/A' }}</span>
                        <span class="subtitle">Código de proveedor: {{ item.providerCode || 'N/A' }}</span>

                        <span class="subtitle">Categoria del producto: {{ item.productCategory.name || 'N/A' }}</span>
                      </div>
                    </div>
                  </div>
                </v-col>

              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.code }}</span>
            </td>
            <td>
              <span>{{ item.name }}</span>
            </td>
            <td>
              <span>{{ item.productCategory.name }}</span>
            </td>
            <td>
              <span>{{ item.providerCode }}</span>
            </td>

            <td>
              <div class="display-align-center">
                <v-icon
                    v-if="hasAccess('/products', 'POST')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudProducts', 'edit')"
                >mdi-pencil-outline
                </v-icon>
                <v-icon
                    v-else-if="hasAccess('/products', 'GET')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudProducts', 'edit')"
                >mdi-magnify
                </v-icon>
                <v-icon
                    v-if="hasAccess('/products', 'DELETE')"
                    class="ml-2 cursor-pointer-action"
                    color="tableActions"
                    @click="deleteRow(item, 'CrudProducts', 'delete')"
                >mdi-trash-can-outline
                </v-icon>

              </div>
            </td>
            <td @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded" class="cursor-pointer-action"
              >mdi-chevron-up
              </v-icon>
              <v-icon v-else class="cursor-pointer-action"
              >mdi-chevron-down
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Producto'"
        :message="message"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import ActionPopUp from "@/components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import DownloadBtn from "../downloadBtn.vue";
import customStore from "@/services/customStore";

export default {
  name: "productsResume",
  components: {DownloadBtn, ActionPopUp},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "products",
      embedded: 'ProductCategory',
      dialogProductsUpload: false,
      productsFile: null,
      fixInventoryFile:null
    };
  },
  computed:{
    completeStore() {
      return this.storeName || ''
    },
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Código local",
          sortable: true,
          value: "code",
        },
        {
          text: "Nombre",
          value: "name",
          sortable: true,
        },
        {
          text: "Categoría del producto",
          sortable: false,
          value: "productCategory.name",
        },
        {
          text: "Código de proveedor",
          sortable: false,
          value: "providerCode",
        },
        {text: "", value: "data-table-expand"},
        {text: "", value: "actions"},
      ];
    },
    closeUploadForm() {
      this.dialogProductsUpload = false
      this.fixInventoryFile = null
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('file', this.productsFile);

      await customStore.uploadProducts(formData);

      this.productsFile = null;
      this.dialogProductsUpload = false;
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.personal-information-text > .name {
  font-family: "Montserrat-Regular";
  font-size: 17px;
}

.personal-information-text > .subtitle {
  font-family: "Montserrat-Regular";
  font-size: 14px;
}
</style>
