<template>
  <v-row no-gutters>
    <v-col cols="12" lg="12" md="12" xl="12" xs="12">
      <v-skeleton-loader
        :type="type"
        class="mx-auto border"
        max-width="auto"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SkeletonLoaded",
  components: {},
  props: {
    type: {
      type: String,
      default: "image, article",
    },
  },
  data() {
    return {};
  },
};
</script>
