<template>
    <div class="mt-5">
        <div>
            <v-row no-gutters>
                <v-col class="pa-0" cols="12" lg="8" md="8" sm="12" xl="8">
                    <v-text-field
                            v-model="search"
                            :label="label"
                            hide-details
                            outlined
                            prepend-icon="mdi-magnify"
                            @click:append="cleanText"
                            :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
                            single-line
                            @input="typingSearch"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                    :headers="headers"
                    :items="dataSource"
                    :loading="loaded"
                    :options.sync="options"
                    :mobile-breakpoint="200"
                    :search="search"
                    :server-items-length="totalItems"
                    class="mt-5"
                    hide-default-header
                    item-key="uuid"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 40, 1000]
                     }"
                    @click:headers="handleHeaderClick"
            >
              <template v-slot:header="{ props }">
                <thead class="v-data-table-header">
                <tr>
                  <th class="text-start" v-for="header in props.headers" :key="header.value" @click="handleHeaderClick(header)">
                    <span>
                        {{ header.text }}
                    </span>
                  </th>
                </tr>
                </thead>
              </template>

                <template v-slot:item="{ item }">
                    <tr
                            :class="'tr-title-header'"
                    >
                      <td>
                        <span>{{ item.name }}</span>
                      </td>
                      <td>
                            <span>{{ item.description}}</span>
                        </td>
                        <td>
                            <div class="display-align-center">
                                <v-icon
                                        class="cursor-pointer-action"
                                        color="tableActions"
                                        @click="editRow(item, 'CrudRole', 'edit')"
                                >mdi-magnify
                                </v-icon>
                              <v-icon
                                  class="ml-2 cursor-pointer-action"
                                  color="tableActions"
                                  @click="deleteRow(item, 'CrudRole', 'delete')"
                              >mdi-trash-can-outline
                              </v-icon>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
      <ActionPopUp
          :dialog="dialog"
          :iteName="'Role'"
          :type="type"
          :message="message"
          @cancelAction="dialog = false"
          @closeAction="closeAction"
          @deleteAction="deleteAction"
      ></ActionPopUp>
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import moduleMixin from "@/mixins/moduleMixin";
import ActionPopUp from "@/components/ActionPopUp.vue";

export default {
    name: "RoleResume",
    components: {ActionPopUp},
    mixins: [tableMixin, moduleMixin],
    data() {
        return {
            module: "role"
        };
    },
    methods: {
        afterProcess() {
            this.headers = [
              {
                text: "Role",
                value: "name",
                sortable: true,
              },
              {
                text: "Descripción",
                value: "description",
                sortable: true,
              },
                {text: "", value: "actions"},
            ];
        },
    },
};
</script>

<style scoped>
.personal-information-text > .name {
    font-family: "Montserrat-Regular";
    font-size: 17px;
}

.personal-information-text > .subtitle {
    font-family: "Montserrat-Regular";
    font-size: 14px;
}
</style>
