<template>
    <div>
        <div v-show="!enableCamera"
             class="image-container main-display-column-center"
             @click="$refs.fileInput.click()"
             @drop="handleDrop"
             @dragover.prevent
        >
            <div class="image-container-p main-display-column-center">
                <div v-if="!uploading" class="main-display-column-center">
                    <div>
                        <v-icon size="50">mdi-image-outline</v-icon>
                    </div>
                    <div class="mt-4">
                        <span class="image-container-text">Take or upload an image</span>
                    </div>
                </div>
                <div v-else>
                    <v-progress-circular
                            color="image-container-text"
                            indeterminate
                    ></v-progress-circular>
                </div>
            </div>
            <input
                    ref="fileInput"
                    accept="image/*"
                    style="display: none"
                    type="file"
                    @change="handleFileChange"
            />
        </div>
        <div v-show="enableCamera">
            <take-photo ref="takePhoto" @uploadImage="uploadImage"></take-photo>
        </div>
        <div class="display-item-center">
            <v-switch v-model="enableCamera" :label="enableCamera ? 'Files' : 'Camera'" @change="stopVideo"></v-switch>
        </div>
        <ActionPopUp
                :dialog="dialog"
                :iteName="'File'"
                :type="type"
                fileInfo
                @cancelAction="dialog = false"
                @closeAction="dialog = false"
        ></ActionPopUp>
    </div>

</template>

<script>
import customStoreService from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";
import TakePhoto from "@/components/TakePhoto.vue";

export default {
    name: "UploadFiles",
    components: {TakePhoto, ActionPopUp},
    props: {
        ownerId: {type: String, default: ""},
    },
    data() {
        return {
            uploading: false,
            type: "error",
            dialog: false,
            enableCamera: false
        };
    },
    methods: {
        handleDrop(e) {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (this.validateFiles(files)) {
                this.handleFiles(files);
            }
        },
        handleFileChange(e) {
            const files = e.target.files;
            if (this.validateFiles(files)) {
                this.handleFiles(files);
            }
        },
        async handleFiles(files) {
            if (!this.ownerId) {
                return;
            }
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append("files[]", file);
            }
            try {
                this.uploading = true;
                await customStoreService.postImageByOwner(this.ownerId, formData);
                this.$emit("downloadFiles");
                this.uploading = false;
                this.uploadInfo(true);
            } catch (e) {
                this.uploading = false;
                this.uploadInfo(false);
            }
        },
        uploadInfo(e) {
            if (e) {
                this.type = "success";
                this.dialog = true;
            } else {
                this.type = "error";
                this.dialog = true;
            }
        },
        validateFiles(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (!file.type.startsWith("image/")) {
                    this.type = "errorImage";
                    this.dialog = true;
                    return false;
                }
            }
            return true;
        },
        async uploadImage(image) {
            const file = this.base64toFile(image, 'photoCapture.jpg')
            const formData = new FormData();
            formData.append("files[]", file);
            try {
                this.uploading = true;
                await customStoreService.postImageByOwner(this.ownerId, formData);
                this.$emit("downloadFiles");
                this.uploading = false;
                this.uploadInfo(true);
            } catch (e) {
                this.uploading = false;
                this.uploadInfo(false);
            }
        },
        base64toFile(base64, filename) {
            const arr = base64.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            const blob = new Blob([u8arr], {type: mime});
            return new File([blob], filename, {type: mime});
        },
        stopVideo(e) {
            if (!e) {
                this.$refs.takePhoto.initCamera()
            } else {
                this.$refs.takePhoto.initCamera()
            }

        }
    },
};
</script>

<style lang="scss">
.image-container {
  cursor: pointer;
  background-color: var(--v-boxUpload-base);
  height: 230px;
  width: 230px;
  min-width: 200px;
  max-width: 230px;
  min-height: 200px;
  max-height: 230px;
  border-radius: 8px;

  .image-container-p {
    padding: 20px;

    .image-container-text {
      font-size: 15px;
      font-family: "Montserrat-Medium";
      font-weight: 600;
      color: var(--v-boxUploadText-base);
    }
  }
}
</style>
