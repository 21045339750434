<template>
  <div class="mt-5">
    <div>
      <v-row >
        <v-col  cols="12" lg="8" md="8" sm="8" xl="8">
          <v-text-field
            v-model="search"
            :label="label"
            hide-details
            outlined
            prepend-icon="mdi-magnify"
            @click:append="cleanText"
            :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
            single-line
            @input="typingSearch"
          />
        </v-col>

        <v-col  cols="12" lg="4" md="4" sm="4" xl="4">
          <v-select
              v-model="statusFilter"
              @change="changeFilterStatus"
              :items="statusList"
              item-text="name"
              item-value="id"
              label="Status"
              multiple
              outlined
          />
        </v-col>

      </v-row>
      <v-data-table
        :expanded.sync="expanded"
        :headers="headers"
        :items="dataSource"
        :loading="loaded"
        :mobile-breakpoint="200"
        :options.sync="options"
        :search="search"
        :server-items-length="totalItems"
        :single-expand="singleExpand"
        class="mt-5"
        item-key="uuid"
        show-expand

        :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >
<!--        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value" @click="handleHeaderClick(header)">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="6" xl="6">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-store</v-icon>
                      <span class="ml-1">Información de inventario</span>
                    </div>
                  </div>
                  <div class="px-6 mt-3">
                    <v-row class="info-element-text" no-gutters>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Producto</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>
                          {{ item.product.name || "Sin información" }}
                        </span>
                      </v-col>
                      <v-col
                          class="mt-2"
                          cols="12"
                          lg="4"
                          md="12"
                          sm="12"
                          xl="4"
                      >
                        <span>Categoría</span>
                      </v-col>
                      <v-col
                          class="mt-2"
                          cols="12"
                          lg="8"
                          md="12"
                          sm="12"
                          xl="8"
                      >
                        <span>
                          {{ item.product?.productCategory?.name || "Sin información" }}
                        </span>
                      </v-col>

                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Total</span>
                      </v-col>
                      <v-col v-for="(itemN, index) in item.storeStockDetail"
                             :key="index"
                        class="mt-2"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                        xl="12"
                      >
                        <div style="display: flex; flex-direction: column">
                          <span>Cantidad: {{ itemN.stock || "Sin información" }}</span>
                          <span>Fecha: {{ formatTime(itemN.expirationDate) || "Sin información" }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.product?.providerCode || item.product?.code  }}</span>
            </td>
            <td>
              <span>{{ item.product.name  }}</span>
            </td>
            <td>
              <span>{{ item.product?.productCategory?.name  }}</span>
            </td>
            <td>
              <span>{{ item.stock }}</span>
            </td>
            <td>
              <div class="border-circle" :style="{'background-color': statusInventory[item.status]}"></div>
            </td>
            <td @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
      :dialog="dialog"
      :iteName="'Store'"
      :message="message"
      :type="type"
      @cancelAction="dialog = false"
      @closeAction="closeAction"
      @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import moment from "moment/moment";


export default {
  name: "InventoryStoreByStoreResume",
  computed: {
    moment() {
      return moment
    }
  },
  components: { ActionPopUp },
  mixins: [tableMixin, moduleMixin],
  props:{
    storeId:{
      required: true, default:'', type:String
    }
  },
  data() {
    return {
      module: "store-stocks",
      label: "Busca por nombre o cantidad",
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      statusFilter: [],
      status:``,
      statusList: [{name:'Crítico', id:'CRITICAL'}, {name:'Bueno', id:'GOOD'}, {name :'Mínimo', id:'MINIMAL'}],
    };
  },
  methods: {
    formatTime(time) {
      if (!time) return "N/A"
      return moment(time).format("YYYY-MM-DD")
    },
    changeFilterStatus() {
      let statusFormatted = "";
      this.statusFilter.forEach((status) => {
        statusFormatted = `${statusFormatted}&status=${status}`
      });
      this.status = statusFormatted;
      this.loadData()
    },
    afterProcess() {
      this.embedded = 'ProductUnit.Product'
     this.additionalParams = {
        storeId: this.storeId
      }
      this.headers = [
        {
          text: "Código",
          sortable: true,
          value: "code",
        },
        {
          text: "Producto",
          sortable: true,
          value: "product.name",
        },
        {
          text: "Categoría",
          sortable: true,
          value: "product.productCategory.name",
        },
        {
          text: "Total",
          sortable: true,
          value: "stock",
        },
        {
          text: "Status",
          sortable: true,
          value: "status",
        },
        { text: "", value: "data-table-expand" }
      ];
    },
  },
};
</script>

<style  lang="scss">

.border-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
