import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import contract from "./contract";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        contract
    }
})
