<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="9" md="9" sm="6" xl="9" xs="12">
          <v-text-field
            v-model="search"
            :label="label"
            hide-details
            outlined
            prepend-icon="mdi-magnify"
            @click:append="cleanText"
            :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
            single-line
            @input="typingSearch"
          />
        </v-col>
<!--        <v-col  cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
          <download-btn module="/inventory/download" titleBtn="Inventario" fileDownloadName="Inventario"></download-btn>
        </v-col>-->
      </v-row>
      <v-data-table
        :expanded.sync="expanded"
        :headers="headers"
        :items="dataSource"
        :mobile-breakpoint="200"
        :loading="loaded"
        :options.sync="options"
        :search="search"
        :server-items-length="totalItems"
        :single-expand="singleExpand"
        class="mt-5"
        item-key="storeId"
        show-expand
        hide-default-header
        :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value" @click="handleHeaderClick(header)">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="6" xl="6">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-store</v-icon>
                      <span class="ml-1">Store Information</span>
                    </div>
                  </div>
                  <div class="px-6 mt-3">
                    <v-row class="info-element-text" no-gutters>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Tienda</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>
                          {{ item.storeName || "Sin información" }}
                        </span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Total</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>{{ item.totalStock || "Sin información" }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.storeName }}</span>
            </td>
            <td>
              <span>{{ item.totalStock }}</span>
            </td>
            <td>
              <div class="display-align-center">
                <v-icon
                    v-if="hasAccess('/inventory-stores', 'POST')"
                  class="cursor-pointer-action"
                  color="tableActions"
                  @click="editRow(item, 'CrudInventoryStores', 'edit',null,  item?.storeId)"
                >
                  mdi-magnify
                </v-icon>
                <v-icon
                    v-else-if="hasAccess('/inventory-stores', 'GET')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudInventoryStores', 'edit',null,  item?.storeId)"
                >
                  mdi-magnify
                </v-icon>
              </div>
            </td>
            <td @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
      :dialog="dialog"
      :iteName="'Store'"
      :type="type"
      :message="message"
      @cancelAction="dialog = false"
      @closeAction="closeAction"
      @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
/*import DownloadBtn from "../downloadBtn.vue";*/

export default {
  name: "InventoryStoreResume",
  components: { ActionPopUp },
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "store-stocks/inventory",
      label: "Busca por nombre o cantidad",
    };
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Nombre",
          sortable: true,
          value: "storeName",
        },
        {
          text: "Total",
          sortable: true,
          value: "totalStock",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ];
    },
  },
};
</script>

<style scoped></style>
