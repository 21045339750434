<template>
  <v-container >
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction :show-right-item="true"
                   subtitle="Consulta busca y filtra registros"
                   :validBtn="hasAccess('/patients', 'POST')"
                   @setDefaultPrimaryAction="createNewRegister"
                   principal-btn-title="NUEVO REGISTRO"
                   title="Pacientes"/>
    <register-resume></register-resume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "../components/TopInfoAction";
import RegisterResume from "@/components/registers/RegisterResume.vue";
import moduleMixin from "../mixins/moduleMixin";


export default {
  name: "Dashboard",
  components: {RegisterResume, Breadcrumbs, TopInfoAction},
  mixins: [moduleMixin],
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Pacientes",
        disabled: true,
        path: "/patients",
      },
    ],
  }),
  async mounted() {

  },
  methods: {
    createNewRegister() {
      this.$router.push({
        name: "CrudRegister",
        params: {crudAction: "create"},
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.card-color-description-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px 20px 10px;
  background-color: var(--v-calendarDescriptionBg-base);

  .description-calender-text {
    color: var(--v-textCalendar-base);
    font-family: 'Montserrat-Regular';
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
  }

  .circle-container {
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }
}

.main-container-detail {
  .contract-container {
    height: auto;
    max-height: 75vh;
    display: flex;
    flex-direction: column;

    .contract-detail {
      padding: 10px;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;

      span {
        font-family: 'Montserrat-Medium';
        font-size: 17px;
        font-weight: 600;
      }
    }

    .contract-detail::-webkit-scrollbar {
      width: 5px;
    }

    .contract-detail::-webkit-scrollbar-track {
      background: #f2f2f2;
    }

    .contract-detail::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 10px;
    }
  }

  .no-contracts {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--v-boxUpload-base);
    margin-top: 10px;
    font-family: 'Montserrat-Regular';

  }
}

</style>
