<template>
  <div>
    <span class="input-label"
    >{{ info }}
      <span v-if="isRequired" class="required-field-form">*</span></span
    >
    <div class="display-column-card mt-3">
      <div v-for="(item, index) in availableOption" :key="index">
        <slot :item="item" name="itemContent">
          <div
              v-if="!isArrayObject"
              :class="
              currentElement === item
                ? 'box-content-item-selected'
                : 'box-content-item'
            "
              @click="selectItem(item)"
          >
            {{ item }}
          </div>
          <div
              v-else
              :class="showSelectedOptions(item)"
              @click="selectItem(item)"
          >
            {{ item[propertyName] }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectCards",
  props: {
    availableOption: {
      type: Array,
      default: () => [],
    },
    availableItems: {
      type: Array,
      default: () => [],
    },
    info: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    currentElement: {
      type: String,
      default: "",
    },
    isArrayObject: {
      type: Boolean,
      default: false,
    },
    propertyName: {
      type: String,
      default: "",
    },
    isOneElement: {
      type: Boolean,
      default: false,
    },
    oneElementString: {
      type: String,
      default: "",
    },
    multipleSelect: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    selectedBox() {
      return this.currentElement;
    },
  },
  methods: {
    selectItem(e) {
      this.$emit("selectedItem", e);
    },
    showSelectedOptions(item) {
      if (!this.isOneElement && this.availableItems) {
        const index = this.availableItems.findIndex(
            (option) => option.uuid === item.uuid
        );
        if (index !== -1) {
          return "box-content-item-selected";
        }
        return "box-content-item";
      }
      if (item.uuid === this.oneElementString) {
        return "box-content-item-selected";
      }
      return "box-content-item";
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content-item {
  padding: 18px 35px 19px 25px;
  border-radius: 5px;
  border: solid 1px var(--v-borderBox-base);
  cursor: pointer;
  font-family: "Montserrat-Regular";
  font-size: 15px;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 950px) {
    padding: 10px;
  }
}

.box-content-item-selected {
  padding: 18px 35px 19px 25px;
  border-radius: 5px;
  box-sizing: border-box;
  border: solid 1px var(--v-borderBox-base);
  background-color: var(--v-selectedBox-base);
  cursor: pointer;
  font-family: "Montserrat-Regular";
  font-size: 15px;
  color: white;
  text-align: center;
  @media (max-width: 950px) {
    padding: 10px;
  }
}

.display-column-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}
</style>
