import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters("auth", ["getResources"])
    },
    methods: {
        hasAccess(module, type) {
            let hasAccess = false;
            this.getResources.forEach((itemRes) => {
                if (itemRes?.resourceName === module) {
                    if (itemRes.permission === type) {
                        hasAccess = true;
                    }
                }
            });
            return hasAccess;
        },
    },
};
