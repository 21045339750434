<template>
  <div>
    <title-info-card icon="mdi-file-outline" textTitle="Files">
      <template #contentCard>
        <span>{{ msgInfo }}</span>
        <div v-if="ownerId && files.length" class="image-gallery mt-3">
          <div
              v-for="(image, index) in files"
              :key="index"
              :class="
              selectedImg === image.name ? 'image image-selected' : 'image'
            "
          >
            <img :src="image.url" alt="Image"/>
            <div v-if="selectedImg === image.name" class="overlay-selected">
              <span>selected</span>
            </div>
            <div class="overlay display-item-column-center">
              <v-btn
                  v-if="showSelect && selectedImg !== image.name"
                  class="ma-2 white--text"
                  color="successImage"
                  small
                  @click="selectImage(image)"
              >
                Select
                <v-icon dark right> mdi-check</v-icon>
              </v-btn>
              <v-btn
                  class="ma-2 white--text"
                  color="error"
                  small
                  @click="deleteImage(image)"
              >
                Delete
                <v-icon dark right> mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="image-gallery">
          No images available, please upload an image to continue
        </div>
      </template>
    </title-info-card>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'File'"
        :type="type"
        fileInfo
        @cancelAction="dialog = false"
        @closeAction="dialog = false"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import TitleInfoCard from "@/components/TitleInfoCard.vue";
import customStoreService from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";

export default {
  name: "ShowFilesUploaded",
  components: {ActionPopUp, TitleInfoCard},
  props: {
    msgInfo: {type: String, default: ""},
    ownerId: {type: String, default: ""},
    selectedImg: {type: String, default: ""},
    showSelect: {type: Boolean, default: true}
  },
  data() {
    return {
      files: [],
      dialog: false,
      type: "error",
      selectedImage: {},
    };
  },
  async mounted() {
    await this.getFilesByOwnerId();
  },
  methods: {
    async getFilesByOwnerId() {
      if (!this.ownerId) {
        return;
      }
      try {
        this.files = await customStoreService.getImagesByOwner(this.ownerId);
      } catch {
        this.files = [];
      }
    },
    deleteImage(image) {
      this.type = "delete";
      this.selectedImage = image;
      this.dialog = true;
    },
    selectImage(image) {
      this.$emit("selectImage", image.name);
    },
    async deleteAction() {
      try {
        await customStoreService.deleteFileByOwner(
            this.ownerId,
            this.selectedImage
        );
        this.$emit('reloadData')
        if (this.selectedImage.name === this.selectedImg) {
          this.$emit("selectImage", "");
        }
        this.dialog = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
.image-gallery {
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: var(--v-boxUploadedFiles-base);
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-columns: minmax(0, max-content);
  grid-gap: 1rem;
}

.image {
  position: relative;
}

.image-selected {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
  border: 3px solid var(--v-selectedImage-base);
}

.image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.image:hover .overlay {
  opacity: 1;
}

.overlay-selected {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--v-boxUploadText-base);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0.4;

  span {
    font-size: 25px;
    color: white;
    font-family: "Montserrat-Bold";
    font-weight: 600;
    color: white;
  }
}
</style>
