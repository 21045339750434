import Axios from 'axios';
import {urlFull} from "@/services/config"

const BASE_LOGIN_URL = "/authorization"

const loginManagement = {
    login(data) {
        return Axios.post(urlFull(BASE_LOGIN_URL), data).then((res) => res.data)
    },
    refreshToken(refreshToken) {
        return Axios.post(urlFull(`${BASE_LOGIN_URL}/refresh?token=${refreshToken}`)).then((res) => res.data)
    },
}

export default loginManagement
