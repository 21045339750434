<template>
  <v-row no-gutters>
    <div class="display-item-bottom">
      <v-btn
          v-if="showSecondaryBtn"
          @click="setDefaultSecondaryAction"
          rounded
          class="btn-active v-btn-secondary"
      >
        {{ secondaryBtnTitle }}
      </v-btn>
      <v-btn
          v-if="principalBtnTitle"
          @click="setDefaultPrimaryAction"
          rounded
          :disabled="!validBtn"
          class="btn-active v-btn-primary"
      >
        {{ principalBtnTitle }}
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "BottomActions",
  props: {
    principalBtnTitle: {
      type: String,
      default: "",
    },
    secondaryBtnTitle: {
      type: String,
      default: "",
    },
    validBtn: {
      type: Boolean,
      default: true,
    },
    showSecondaryBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setDefaultPrimaryAction() {
      this.$emit("setDefaultPrimaryAction");
    },
    setDefaultSecondaryAction() {
      this.$emit("setDefaultSecondaryAction");
    },
  },
};
</script>

<style scoped></style>
