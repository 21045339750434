import {
    CONTRACT
} from "../utils/constants";

const templateContract = {
    startDateTime: '',
    endDateTime: '',
    startTime: '',
    endTime: '',
    pickupStoreId: '',
    returnStoreId: '',
    vehicleId: '',
    clientId: '',
    cost: '',
    paymentMethod: '',
    status: '',
    employeeId: '',
    observations: '',
    discount: '',
    losingKeyCharge: '',
    rate: '',
    pickup: {
        uuid: '',
        contractId: '',
        type: 'Pickup',
        dateTime: '',
        combustible: '',
        speedometer: '',
        observations: ''
    },
    return: {
        uuid: '',
        contractId: '',
        type: 'Return',
        dateTime: '',
        combustible: '',
        speedometer: '',
        observations: ''
    }
};

export default {
    namespaced: true,
    state: {
        currentContract: templateContract,
        currentStep: 1
    },
    mutations: {
        SET_CURRENT_ELEMENT: (state, payload) => {
            localStorage.setItem(CONTRACT, JSON.stringify(payload));
            state.currentContract = payload;
        },
        CLEAR_CURRENT_ELEMENT: (state) => {
            localStorage.removeItem(CONTRACT);
            state.currentContract = {
                startDateTime: '',
                endDateTime: '',
                startTime: '',
                endTime: '',
                pickupStoreId: '',
                returnStoreId: '',
                vehicleId: '',
                clientId: '',
                cost: '',
                paymentMethod: '',
                status: '',
                employeeId: '',
                observations: '',
                discount: '',
                losingKeyCharge: '',
                rate: '',
                pickup: {
                    uuid: '',
                    contractId: '',
                    type: 'Pickup',
                    dateTime: '',
                    combustible: '',
                    speedometer: '',
                    observations: ''
                },
                return: {
                    uuid: '',
                    contractId: '',
                    type: 'Return',
                    dateTime: '',
                    combustible: '',
                    speedometer: '',
                    observations: ''
                }
            };
        },
        SET_CURRENT_STEP: (state, payload) => {
            state.currentStep = payload;
        },
    },
    actions: {
        setCurrentElement({commit}, payload) {
            commit("SET_CURRENT_ELEMENT", payload);
        },
        clearCurrentElement({commit}) {
            commit("CLEAR_CURRENT_ELEMENT");
        },
        setCurrentStep({commit}, payload) {
            commit("SET_CURRENT_STEP", payload);
        },
    },
    getters: {},
};
