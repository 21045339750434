import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from "./utils/i18n";

Vue.config.productionTip = false

/** Interceptor*/
import interceptorsSetup from '@/plugins/axios'

interceptorsSetup()

new Vue({
    store,
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')

