<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validForm"
        principal-btn-title="Guardar"
        secondary-btn-title="Cancelar"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <RoleForm  @validFormInput="validFormInput" :current-element="currentElement"
                 :resources="availableResources"
                 :assigned-resource="availableAssignedResources"
                 :role-id="$route.params?.id" @reloadResources="getResourceAndFilterByOwner"></RoleForm>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="validForm"
          principal-btn-title="Guardar"
          secondary-btn-title="Cancelar"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Role'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import RoleForm from "../components/roles/RoleForm.vue";
import customStore from "../services/customStore";


export default {
  name: "CRUDRole",
  mixins: [CRUDMixin],
  props: {
    pathBack: {type: String, default: "permission"},
  },
  components: {
    RoleForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Roles",
          disabled: false,
          path: "/role",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Role`,
          disabled: true,
          path: "/role",
        },
      ],
      currentElement: {
        name: "",
      },
      module: "role",
      titleCreate: "Crear nuevo role",
      titleEdit: "Editar role",
      availableResources:[],
      availableAssignedResources:[],
      redirectAfterSave:true
    };
  },

  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
    beforeSend() {
      this.currentElement.active = true;
    },
   async loadData() {
      if (!this.$route.params?.id) return
     await this.getResourceAndFilterByOwner()
    },
   async getResourceAndFilterByOwner() {
      await this.getResourcesByRole()
      await this.getResources()

      if (this.availableAssignedResources.length) {
        let onlyAvailableResources = []
        for (let i=0; i<this.availableResources.length; i++) {
          let availableResource = this.availableResources[i]
          const findResource = this.availableAssignedResources.find((item)=> item.resource.uuid === availableResource.uuid)
          if (!findResource) {
            onlyAvailableResources.push(availableResource)
          }
        }
          this.availableResources = onlyAvailableResources
      }
    },
    async getResources() {
      try {
       const {data} = await customStore.getDataByModule('resource', '', 0, '1000')
        this.availableResources =  data
      }catch (error) {
        console.log(error)
      }
    },
    async getResourcesByRole() {
      try {
        const res = await customStore.getDataCrudByModule('resource/role', this.$route.params.id)
        this.availableAssignedResources = res
      }catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style scoped></style>
