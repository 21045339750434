<template>
    <v-row class="web-camera-container">
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
            <div class="display-item-center">
                <v-btn text @click="toggleCamera">{{ !isCameraOpen ? 'Open Camera' : 'Close Camera' }}</v-btn>
            </div>
            <div v-if="isCameraOpen && isLoading" class="camera-loading">
                <ul class="loader-circle">
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </v-col>

        <v-row>
            <v-dialog
                    v-model="isCameraOpen"
                    persistent
                    width="auto"
            >
                <v-card>
                    <div>
                        <div class="pa-3">
                            <div v-show="isCameraOpen && !isLoading" class="camera-box">
                                <video v-show="!isPhotoTaken" ref="camera" autoplay></video>
                                <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                            </div>
                            <div v-if="isCameraOpen && !isLoading" class="display-item-center mt-2">
                                <v-btn
                                        class="mx-2  mb-5"
                                        color="secondary"
                                        dark
                                        fab
                                        @click="toggleCamera"
                                >
                                    <v-icon dark size="25">
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                        class="mx-2  mb-5"
                                        color="primary"
                                        dark
                                        fab
                                        @click="takePhoto"
                                >
                                    <v-icon v-if="!isPhotoTaken" dark size="25">
                                        mdi-camera-outline
                                    </v-icon>
                                    <v-icon v-else dark size="25">mdi-camera-retake-outline</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="isPhotoTaken" class="main-display-column-center">
                                <v-btn text @click="downloadImage">Upload photo</v-btn>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
    </v-row>

</template>

<script>
export default {
    name: "TakePhoto",
    components: {},
    props: {},
    data() {
        return {
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            link: '#'
        }
    },
    async beforeDestroy() {
        await this.initCamera()
    },
    methods: {
        async initCamera() {
            await this.stopCameraStream();
            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;
        },
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },

        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true
            });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error)
                });
        },

        stopCameraStream() {
            if (this.$refs.camera && this.$refs.camera.srcObject) {
                let tracks = this.$refs.camera.srcObject.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
            }
        },

        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;
                const FLASH_TIMEOUT = 50;
                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;
            const canvas = this.$refs.canvas;
            canvas.width = this.$refs.camera.videoWidth;
            canvas.height = this.$refs.camera.videoHeight;
            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, canvas.width, canvas.height);
        },

        downloadImage() {
            const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
            this.takePhoto()
            this.$emit('uploadImage', canvas)
        }
    }
}
</script>

<style lang="scss" scoped>
.web-camera-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: auto;
  width: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  .camera-loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .loader-circle {
      display: flex;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: .2s;
        }

        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1
    }
    50% {
      opacity: .4
    }
    100% {
      opacity: 1
    }
  }
}

.camera-box {
  border-radius: 4px;

  video {
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }

  canvas {
    height: 100%;
    width: 100%;
  }
}
</style>
