<template>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-btn
            style="width: 100%"
            class="btn-active v-btn-primary"
            rounded
            @click="download"
        >
          <v-icon>{{iconBtn}}</v-icon>
          {{titleBtn}}
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>
import customStore from "../services/customStore";

export default {
  name: "downloadBtn",
  props:{
    titleBtn:{
      type: String,
      default: ''
    },
    module:{
      type: String,
      default: ''
    },
    fileDownloadName:{
      type: String,
      default: 'File'
    },
    iconBtn:{
      type: String,
      default:'mdi-file-excel'
    },
    storeId:{
      type:Array,
      default: ()=> []
    }
  },
  methods:{
    getCurrentDate() {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
   async download() {
     try {
       const date = this.getCurrentDate()
       await customStore.generalDownloadFile(this.module, `${this.fileDownloadName}-${date}`, 'xlsx', this.storeId)
     }catch {
       //do nothing
     }
    }
  }
}
</script>


<style scoped lang="scss">

</style>