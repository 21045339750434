<template>
    <div class="mt-5">
        <div>
            <v-row no-gutters>
                <v-col class="pa-0" cols="12" lg="8" md="8" sm="12" xl="8">
                    <v-text-field
                            v-model="search"
                            :label="label"
                            hide-details
                            outlined
                            prepend-icon="mdi-magnify"
                            @click:append="cleanText"
                            :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
                            single-line
                            @input="typingSearch"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                    :expanded.sync="expanded"
                    :headers="headers"
                    :items="dataSource"
                    :loading="loaded"
                    :mobile-breakpoint="200"
                    :options.sync="options"
                    :search="search"
                    :server-items-length="totalItems"
                    :single-expand="singleExpand"
                    class="mt-5"
                    item-key="uuid"
                    show-expand
                    :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
            >
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="bg-expanded-row">
                        <main class="detail-container-row">
                            <v-row no-gutters>
                                <v-col cols="12" lg="5" md="5" sm="5" xl="5">
                                    <div>
                                        <div class="display-align-center principal-detail-row">
                                            <v-icon color="tableHeader">mdi-face-man-outline</v-icon>
                                            <span class="ml-1">Información general</span>
                                        </div>
                                    </div>
                                    <div class="px-6 mt-3">
                                        <div cols="12" lg="12" md="12" sm="12" xl="12">
                                            <div
                                                    class="display-item-column-center personal-information-text"
                                            >
                        <span class="name"
                        >{{ item.name }} {{ item.lastName || "" }}</span
                        >
                                                <span class="subtitle">{{ item.email }}</span>
                                                <span class="subtitle">{{ item.phone }}</span>
                                            </div>
                                        </div>
                                        <v-row class="info-element-text" no-gutters>
                                            <v-col
                                                    class="mt-2"
                                                    cols="12"
                                                    lg="4"
                                                    md="12"
                                                    sm="12"
                                                    xl="4"
                                            >
                                                <span>Dirección</span>
                                            </v-col>
                                            <v-col
                                                    class="mt-2"
                                                    cols="12"
                                                    lg="8"
                                                    md="12"
                                                    sm="12"
                                                    xl="8"
                                            >
                        <span>{{
                            formatAddress(item.address) || "Sin Información"
                            }}</span>
                                            </v-col>
                                            <v-col
                                                    class="mt-2"
                                                    cols="12"
                                                    lg="4"
                                                    md="12"
                                                    sm="12"
                                                    xl="4"
                                            >
                                                <span>Año de nacimiento</span>
                                            </v-col>
                                            <v-col
                                                    class="mt-2"
                                                    cols="12"
                                                    lg="8"
                                                    md="12"
                                                    sm="12"
                                                    xl="8"
                                            >
                                                <span>{{ item.birthday || "Sin Información" }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="5" md="5" sm="5" xl="5">
                                    <div>
                                        <div class="display-align-center principal-detail-row">
                                            <v-icon color="tableHeader"
                                            >mdi-text-box-check-outline
                                            </v-icon>
                                            <span class="ml-1">Roles y permisos</span>
                                        </div>
                                        <div class="px-6 mt-3">
                                            <v-row no-gutters>
                                                <v-col cols="12" lg="12" md="12" sm="12" xl="12">
                                                    <v-row class="info-element-text" no-gutters>
                                                        <v-col
                                                                class="mt-2"
                                                                cols="12"
                                                                lg="4"
                                                                md="12"
                                                                sm="12"
                                                                xl="4"
                                                        >
                              <span>{{
                                  displayArrayToString(item.roles)
                                  }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="2" md="2" sm="2" xl="2">
                                    <div
                                            v-if="item.photoImageUrl"
                                            class="img-container-detail"
                                    >
                                        <img
                                                :src="item.photoImageUrl"
                                                alt="Photo"
                                                height="150"
                                                width="150"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </main>
                    </td>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }">
                    <tr
                            :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
                    >
                        <td>
                            <span>{{ item.name }}</span>
                        </td>
                        <td>
                            <span>{{ item.lastName }}</span>
                        </td>
                        <td>
                            <span>{{ item.email }}</span>
                        </td>
                        <td>
                            <span>{{ item.phone }}</span>
                        </td>
                        <td>
              <span class="active-status">{{
                  displayStatus(item.active)
                  }}</span>
                        </td>
                        <td>
              <span class="active-status">{{
                  displayArrayToString(item.roles)
                  }}</span>
                        </td>
                        <td>
                            <div class="display-align-center">
                                <v-icon
                                        v-if="hasAccess('/employees', 'POST')"
                                        class="cursor-pointer-action"
                                        color="tableActions"
                                        @click="editRow(item, 'CrudEmployee', 'edit')"
                                >mdi-pencil-outline
                                </v-icon>
                              <v-icon
                                  v-else-if="hasAccess('/employees', 'GET')"
                                  class="cursor-pointer-action"
                                  color="tableActions"
                                  @click="editRow(item, 'CrudEmployee', 'edit')"
                              >mdi-magnify
                              </v-icon>
                                <v-icon
                                        v-if="hasAccess('/employees', 'DELETE')"
                                        class="ml-2 cursor-pointer-action"
                                        color="tableActions"
                                        @click="deleteRow(item, 'CrudEmployee', 'delete')"
                                >mdi-trash-can-outline
                                </v-icon>

                            </div>
                        </td>
                        <td @click="expand(!isExpanded)">
                            <v-icon v-if="isExpanded" class="cursor-pointer-action"
                            >mdi-chevron-up
                            </v-icon>
                            <v-icon v-else class="cursor-pointer-action"
                            >mdi-chevron-down
                            </v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <ActionPopUp
                :dialog="dialog"
                :iteName="'Empleado'"
                :type="type"
                :message="message"
                @cancelAction="dialog = false"
                @closeAction="closeAction"
                @deleteAction="deleteAction"
        ></ActionPopUp>
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import ActionPopUp from "@/components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";

export default {
    name: "employeeResume",
    components: {ActionPopUp},
    mixins: [tableMixin, moduleMixin],
    data() {
        return {
            module: "employees",
        };
    },
    methods: {
        afterProcess() {
            this.headers = [
                {
                    text: "Nombre",
                    value: "name",
                    sortable: true,
                },
                {
                    text: "Apellidos",
                    sortable: true,
                    value: "lastName",
                },
                {
                    text: "E-mail",
                    sortable: false,
                    value: "email",
                },
                {
                    text: "Teléfono",
                    sortable: false,
                    value: "phone",
                },
                {
                    text: "Status",
                    sortable: true,
                    value: "active",
                },
                {
                    text: "Role",
                    sortable: true,
                    value: "roles",
                },
                {text: "", value: "data-table-expand"},
                {text: "", value: "actions"},
            ];
        },
    },
};
</script>

<style scoped>
.personal-information-text > .name {
    font-family: "Montserrat-Regular";
    font-size: 17px;
}

.personal-information-text > .subtitle {
    font-family: "Montserrat-Regular";
    font-size: 14px;
}
</style>
