<template>
  <v-breadcrumbs class="breadcrumbs-container" :items="items">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :disabled="item.disabled"
          @click="$router.push(item.path)"
          :class="item.disabled ? '' : 'breadcrumbs-item'"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
      required: false
    }
  },
}
</script>

<style scoped lang="scss">
.breadcrumbs-container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .breadcrumbs-item {
    cursor: pointer;
  }
}
</style>
