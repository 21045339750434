export const ACCESS_TOKEN = 'access-token';
export const REFRESH_TOKEN = 'refresh_token';
export const RESOURCES = 'resources';

export const USER = 'user';


export const CONTRACT = 'contract';

export const PAYMENT_METHODS = [
    {
        id: '1',
        name: 'Cash'
    },
    {
        id: '2',
        name: 'Nominal check'
    },
    {
        id: '3',
        name: 'Electronic fund transfer'
    },
    {
        id: '4',
        name: 'Credit card'
    },
    {
        id: '5',
        name: 'Electronic wallet'
    },
    {
        id: '6',
        name: 'Electronic money'
    },
    {
        id: '8',
        name: 'Pantry vouchers'
    },
    {
        id: '12',
        name: 'Settlement'
    },
    {
        id: '13',
        name: 'Subrogation payment'
    },
    {
        id: '14',
        name: 'Payment for consignment'
    },
    {
        id: '15',
        name: 'Condonation'
    },
    {
        id: '17',
        name: 'Compensation'
    },
    {
        id: '23',
        name: 'Novation'
    },
    {
        id: '24',
        name: 'Confusion'
    },
    {
        id: '25',
        name: 'Debt remission'
    },
    {
        id: '26',
        name: 'Prescription or expiration'
    },
    {
        id: '27',
        name: 'To the satisfaction of the creditor'
    },
    {
        id: '28',
        name: 'Debit'
    },
    {
        id: '29',
        name: 'Service card'
    },
    {
        id: '30',
        name: 'Application of advances'
    },
    {
        id: '31',
        name: 'Intermediary payments'
    },
    {
        id: '99',
        name: 'To define'
    }
];

export const PLATFORM = 'WEB'; //DESKTOP, WEB
