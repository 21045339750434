<template>
  <main>
    <h5 class="text-center">Formulario de aplicación</h5>
    <v-form ref="formValidation">
      <section class="mt-10">
        <title-info-card icon="mdi-account"
                         :hide-print="hidePrint"
                         textTitle="Datos personales del adulto mayor">
          <template #contentCard>
            <v-row class="mt-3">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.personalInfo.firstName"
                    label="Nombre"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.personalInfo.lastName"
                    label="Apellidos"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.personalInfo.genre"
                    label="Género"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.personalInfo.maritalStatus"
                    label="Estado civil"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-cake-variant"
                    v-model="currentElement.personalInfo.birthday"
                    label="Fecha de nacimiento"
                    required
                    variant="outlined"
                    @keyup="dateFormatter"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-map-marker"
                    v-model="currentElement.personalInfo.address"
                    label="Domicilio"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-account"
                         :hide-print="hidePrint"
                         textTitle="Datos del representante legal, o persona responsable">
          <template #contentCard>
            <v-row class="mt-3">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.legalRepresentative.firstName"
                    label="Nombre"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.legalRepresentative.lastName"
                    label="Apellidos"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model.number="currentElement.legalRepresentative.age"
                    label="Edad"
                    required
                    type="number"
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model="currentElement.legalRepresentative.relationship"
                    label="Parentesco"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-map-marker"
                    v-model="currentElement.legalRepresentative.address"
                    label="Domicilio"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-phone"
                    v-model="currentElement.legalRepresentative.phone"
                    label="Teléfono"
                    required
                    type="tel"
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-account"
                    v-model.number="currentElement.legalRepresentative.occupation"
                    label="Ocupación"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <h5 class="text-center">Favor de seleccionar la respuesta que describa la situación actual de su familiar</h5>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-food-apple"
                         :hide-print="hidePrint"
                         textTitle="Desde el punto de vista de las atenciones ¿Que cuidados requiere el adulto mayor?">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.careRequirements.mobilityOptions"
                    label="Movilidad"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.careRequirements.dressOptions"
                    label="Vestimenta"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.careRequirements.hygieneOptions"
                    label="Aseo"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.careRequirements.bladderControlOptions"
                    label="Continencia de esfínter"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.careRequirements.feedingOptions"
                    label="Alimentación"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-bottle-tonic-plus"
                         :hide-print="hidePrint"
                         textTitle="Por las limitaciones que padece ¿como es la condición actual del adulto mayor?">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.visualLimitation"
                    label="Limitación visual"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.emotionalState"
                    label="Estado emocional"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.auditoryLimitation"
                    label="Limitación auditiva"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.behavioralDisorders"
                    label="Trastornos de conducta"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.orientation"
                    label="Orientación en el tiempo y en el espacio"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.memoryDisorders"
                    label="Trastornos en la memoria"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-bottle-tonic-plus"
                    v-model="currentElement.currentCondition.inconsistenciesCommunication"
                    label="Incoherencias en la comunicación"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-gymnastics"
                         :hide-print="hidePrint"
                         textTitle="¿El adulto mayor realiza actividades básicas para la vida diaria como alimentarse, vestirse y/o medicarse?">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.currentCondition.householdTasks"
                    label="Escribe una opción"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-vacuum-outline"
                         :hide-print="hidePrint"
                         textTitle="¿El adulto mayor realiza tareas domésticas de la vida diaria que no pueden ser cubiertas debido a sus limitaciones psíquicas o funcionales como es? la preparación de la comida, limpiar la casa, hacer la compra, lavar y planchar la ropa">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-food-apple"
                    v-model="currentElement.currentCondition.mealPreparation"
                    label="Escribe una opción"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-gas-cylinder"
                         :hide-print="hidePrint"
                         textTitle="¿El adulto mayor requiere de oxígeno? En caso de ser AFIRMATIVO Indicar cuantas horas al dia lo utiliza">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-gas-cylinder"
                    v-model="currentElement.medicalInfo.oxygenUsageHoursPerDay"
                    label="Escribe una opción"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-white-balance-sunny"
                         :hide-print="hidePrint"
                         textTitle="¿El adulto mayor tiene úlceras por presión? En caso afirmativo, describa lo siguiente: ¿dónde y en qué parte ?">
          <template #contentCard>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-white-balance-sunny"
                    v-model="currentElement.medicalInfo.pressureUlcerDescription"
                    label="Escribe una opción"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <h5 class="text-center">Reporte médico</h5>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-file-document"
                         :hide-print="hidePrint"
                         textTitle="¿Actualmente el adulto mayor es atendido por un médico?">
          <template #contentCard>
            <div class="mt-3">
              <div>
                <span>* Si la respuesta es AFIRMATIVA, complete la siguiente información</span>
              </div>
              <div>
                <span>* En caso de ser NEGATIVA o esté siendo tratado por alguna institución (IMSS, ISSSTE, U OTRO SEGURO) colocar únicamente la información que se tenga disponible</span>
              </div>
              <div class="mt-3">
                <h5>Emitido por:</h5>
              </div>
            </div>
            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.doctorFirstName"
                    label="Nombre"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.doctorLastName"
                    label="Apellidos"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.certificateIn"
                    label="Certificado en:"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.professionalLicenseNumber"
                    label="Cédula profesional:"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.medicalCheckupFrequency"
                    label="Su revisión médica es cada"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.lastImmunization"
                    label="Última inmunización:"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.covid19Vaccine"
                    label="Vacuna COVID-19:"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model="currentElement.medicalInfo.infectiousDisease"
                    label="¿Padece enfermedad infecciosa-contagiosa?"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model.number="currentElement.medicalInfo.weight"
                    label="Peso Kg."
                    required
                    type="number"
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                    aria-label="name field"
                    density="compact"
                    prepend-inner-icon="mdi-file-document"
                    v-model.number="currentElement.medicalInfo.height"
                    label="Talla"
                    required
                    type="number"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-medical-bag"
                         :hide-print="hidePrint"
                         textTitle="¿Cuáles y cuándo fueron los últimos laboratoriales que el adulto mayor se tomó?">
          <template #contentCard>
            <v-row class="mt-5 mb-10">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="flex-row-end">
                  <v-btn text @click="addNewAnalysis('create')">
                    Agregar nuevo
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-for="(item, index) in currentElement.laboratoryAnalysis" :key="index">
                  <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        aria-label="name field"
                        density="compact"
                        prepend-inner-icon="mdi-medical-bag"
                        v-model="item.description"
                        label="Descripción"
                        required
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        aria-label="name field"
                        density="compact"
                        prepend-inner-icon="mdi-medical-bag"
                        v-model="item.date"
                        label="Fecha"
                        required
                        @keyup="dateFormatter"
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="1" lg="1" xl="1">
                    <div class="display-align-center">
                      <div class="border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="deleteAnalysis(item,index)">mdi-delete</v-icon>
                      </div>
                      <div v-if="item && item.patientId" class="ml-3 border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="addNewAnalysis('edit', item)">mdi-content-save</v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-virus-outline"
                         :hide-print="hidePrint"
                         textTitle="Favor de describir el y/o diagnósticos que padece el adulto mayor">
          <template #contentCard>
            <v-row class="mt-5 mb-10">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="flex-row-end">
                  <v-btn text @click="addNewDiagnosis('create')">
                    Agregar Nuevo
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-for="(item, index) in currentElement.diagnoses" :key="index">
                  <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        aria-label="name field"
                        density="compact"
                        prepend-inner-icon="mdi-virus-outline"
                        v-model="item.description"
                        label="Descripción"
                        required
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="1" lg="1" xl="1">
                    <div class="display-align-center">
                      <div class="border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="deleteDiagnosis(item,index)">mdi-delete</v-icon>
                      </div>
                      <div v-if="item && item.patientId" class="ml-3 border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="addNewDiagnosis('edit', item)">mdi-content-save</v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
      <section class="mt-10">
        <title-info-card icon="mdi-medication"
                         :hide-print="hidePrint"
                         textTitle="Favor de indicar el tratamiento farmacológico y horarios que actualmente se le estén administrando al adulto mayor">
          <template #contentCard>
            <v-row class="mt-5 mb-10">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="flex-row-end">
                  <v-btn text @click="addNewMedication('create')">
                    Agregar Nuevo
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-row v-for="(item, index) in currentElement.medications" :key="index">
                  <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        aria-label="name field"
                        density="compact"
                        prepend-inner-icon="mdi-medication"
                        v-model="item.description"
                        label="Descripción"
                        required
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <v-text-field
                        aria-label="name field"
                        density="compact"
                        prepend-inner-icon="mdi-medication"
                        v-model="item.schedule"
                        label="Horarios"
                        required
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="1" lg="1" xl="1">
                    <div class="display-align-center">
                      <div class="border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="deleteMedication(item,index)">mdi-delete</v-icon>
                      </div>
                      <div v-if="item && item.patientId" class="ml-3 border-container-icon flex-row-align-center-wrap">
                        <v-icon @click="addNewMedication('edit', item)">mdi-content-save</v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </title-info-card>
      </section>
    </v-form>
  </main>
</template>

<script>
import titleInfoCard from "@/components/TitleInfoCard.vue";

export default {
  name: "FormRegister",
  components: {titleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      },
    },
    hideField: {
      type: Boolean,
      default: false,
    },
    hidePrint: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      validForm: false,
    };
  },
  methods: {
    selectedItem(e) {
      this.$emit("selectedItem", e);
    },
    addNewAnalysis(action = 'create', item = null) {
      this.$emit("addNewAnalysis", action, item);
    },
    deleteAnalysis(item, index) {
      this.$emit("deleteAnalysis", item, index);
    },
    addNewDiagnosis(action = 'create', item = null) {
      this.$emit("addNewDiagnosis", action, item);
    },
    deleteDiagnosis(item, index) {
      this.$emit("deleteDiagnosis", item, index);
    },
    addNewMedication(action = 'create', item = null) {
      this.$emit("addNewMedication", action, item);
    },
    deleteMedication(item, index) {
      this.$emit("deleteMedication", item, index);
    },
    formatDate(value) {
      if (!value) {
        return value
      }
      value = value.replace(/[^\d/]/g, '');
      const dateValue = value.replace(/\D/g, '')
      const dateValueLength = dateValue.length
      if (dateValueLength < 2) return dateValue
      if (dateValueLength < 4) {
        return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}`
      }
      return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}/${dateValue.slice(4, 8)}`
    },
    dateFormatter(event) {
      event.target.value = this.formatDate(event.target.value)
    },
  },
};
</script>

<style scoped>

.border-container-icon {
  border-radius: 8px;
  padding: 8px;
  background-color: #d5bea7;

  i {
    color: white;
    cursor: pointer;
  }
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
}

.flex-row-align-center-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

</style>
