<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :validBtn="hasAccess('/store-stocks', 'POST')"
        principal-btn-title="Nueva orden de inventario"
        subtitle="Crea, busca, filtra y visualiza órdenes de inventario"
        title="Órdenes de inventario"
        @setDefaultPrimaryAction="createNewProduct"
    ></top-info-action>
    <StoreStockResume></StoreStockResume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "@/components/TopInfoAction.vue";
import moduleMixin from "@/mixins/moduleMixin";
import StoreStockResume from "../components/storeStock/StoreStockResume.vue";

export default {
  name: "StoreStock",
  mixins: [moduleMixin],
  components: {StoreStockResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Órdenes de inventario",
        disabled: true,
        path: "/store-stocks",
      },
    ],
  }),
  methods: {
    createNewProduct() {
      this.$router.push({
        name: "CrudStoreStock",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
