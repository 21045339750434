<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        principal-btn-title="Nuevo producto"
        subtitle="Crea, busca, filtra y visualiza productos"
        title="Productos"
        :validBtn="hasAccess('/products', 'POST')"
        @setDefaultPrimaryAction="createNewProduct"
    ></top-info-action>
    <products-resume></products-resume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "@/components/TopInfoAction.vue";
import moduleMixin from "@/mixins/moduleMixin";
import ProductsResume from "@/components/products/ProductsResume.vue";

export default {
  name: "Products",
  mixins: [moduleMixin],
  components: {ProductsResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Productos",
        disabled: true,
        path: "/products",
      },
    ],
  }),
  methods: {
    createNewProduct() {
      this.$router.push({
        name: "CrudProducts",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
