<template>
  <v-app>
    <AppBar v-if="!$route.meta.hideAppBar"/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";

export default {
  name: "App",
  components: {
    AppBar,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/scss/index.scss";

</style>
