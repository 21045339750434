<template>

  <v-container max-width="1200">
    <Breadcrumbs class="hiddePrintElement" :items="breadcrumbsItems"/>
    <top-info-action
        class="hiddePrintElement"
        :show-right-item="true"
        :show-secondary-btn="true"
        principal-btn-title="Imprimir"
        secondary-btn-title="Cancelar"
        title="Imprimir Registro"
        @setDefaultSecondaryAction="cancel"
        @setDefaultPrimaryAction="printContract"
    ></top-info-action>
    <div ref="pdfContent" class="container-paper mt-5 mb-10">
      <div class="display-item-justify ">
        <h1>Formulario de registro</h1>
        <img alt="Logo" :src="logo"
             style="max-width: 200px; margin-bottom: 20px;">
      </div>
      <div>
        <div class="group-heading">Datos personales del adulto mayor</div>
        <table v-if="currentElement.personalInfo">
          <tr>
            <th>Nombre:</th>
            <td>{{ currentElement.personalInfo.firstName || '' }}</td>
          </tr>
          <tr>
            <th>Apellido:</th>
            <td>{{ currentElement.personalInfo.lastName || '' }}</td>
          </tr>
          <tr>
            <th>Género:</th>
            <td>{{ currentElement.personalInfo.genre || '' }}</td>
          </tr>
          <tr>
            <th>Estado Civil:</th>
            <td>{{ currentElement.personalInfo.maritalStatus || '' }}</td>
          </tr>
          <tr>
            <th>Fecha de Nacimiento:</th>
            <td>{{ currentElement.personalInfo.birthday || '' }}</td>
          </tr>
          <tr>
            <th>Dirección:</th>
            <td>{{ currentElement.personalInfo.address || '' }}</td>
          </tr>
        </table>
        <div class="group-heading mt-10">Datos del representante legal, o persona responsable</div>
        <table v-if="currentElement.legalRepresentative">
          <tr>
            <th>Nombre:</th>
            <td>{{ currentElement.legalRepresentative.firstName || '' }}</td>
          </tr>
          <tr>
            <th>Apellido:</th>
            <td>{{ currentElement.legalRepresentative.lastName || '' }}</td>
          </tr>
          <tr>
            <th>Edad:</th>
            <td>{{ currentElement.legalRepresentative.age || 0 }}</td>
          </tr>
          <tr>
            <th>Relación:</th>
            <td>{{ currentElement.legalRepresentative.relationship || '' }}</td>
          </tr>
          <tr>
            <th>Dirección:</th>
            <td>{{ currentElement.legalRepresentative.address || '' }}</td>
          </tr>
          <tr>
            <th>Teléfono:</th>
            <td>{{ currentElement.legalRepresentative.phone || '' }}</td>
          </tr>
          <tr>
            <th>Ocupación:</th>
            <td>{{ currentElement.legalRepresentative.occupation || '' }}</td>
          </tr>
        </table>
      </div>
      <div class="group-heading mt-10">Cuidados del adulto mayor</div>
      <table v-if="currentElement.careRequirements">
        <tr>
          <th>Movilidad:</th>
          <td>{{ currentElement.careRequirements.mobilityOptions || '' }}</td>
        </tr>
        <tr>
          <th>Vestimenta:</th>
          <td>{{ currentElement.careRequirements.dressOptions || '' }}</td>
        </tr>
        <tr>
          <th>Higiene:</th>
          <td>{{ currentElement.careRequirements.hygieneOptions || '' }}</td>
        </tr>
        <tr>
          <th>Control de Vejiga:</th>
          <td>{{ currentElement.careRequirements.bladderControlOptions || '' }}</td>
        </tr>
        <tr>
          <th>Alimentación:</th>
          <td>{{ currentElement.careRequirements.feedingOptions || '' }}</td>
        </tr>
      </table>
      <div class="group-heading mt-10">Condición actual del adulto mayor</div>
      <table v-if="currentElement.currentCondition">
        <tr>
          <th>Limitación visual:</th>
          <td>{{ currentElement.currentCondition.visualLimitation || '' }}</td>
        </tr>
        <tr>
          <th>Estado emocional:</th>
          <td>{{ currentElement.currentCondition.emotionalState || '' }}</td>
        </tr>
        <tr>
          <th>Limitación auditiva:</th>
          <td>{{ currentElement.currentCondition.auditoryLimitation || '' }}</td>
        </tr>
        <tr>
          <th>Trastornos del comportamiento:</th>
          <td>{{ currentElement.currentCondition.behavioralDisorders || '' }}</td>
        </tr>
        <tr>
          <th>Preparación de comidas:</th>
          <td>{{ currentElement.currentCondition.mealPreparation || '' }}</td>
        </tr>
        <tr>
          <th>Tareas domésticas:</th>
          <td>{{ currentElement.currentCondition.householdTasks || '' }}</td>
        </tr>
        <tr>
          <th>Orientación:</th>
          <td>{{ currentElement.currentCondition.orientation || '' }}</td>
        </tr>
        <tr>
          <th>Trastornos de la memoria:</th>
          <td>{{ currentElement.currentCondition.memoryDisorders || '' }}</td>
        </tr>
        <tr>
          <th>Inconsistencias en la comunicación:</th>
          <td>{{ currentElement.currentCondition.inconsistenciesCommunication || '' }}</td>
        </tr>
      </table>
      <div class="group-heading mt-10">Reporte médico</div>
      <table v-if="currentElement.medicalInfo">
        <tr>
          <th>Uso de oxígeno (horas por día):</th>
          <td>{{ currentElement.medicalInfo.oxygenUsageHoursPerDay || '' }}</td>
        </tr>
        <tr>
          <th>Descripción de úlcera por presión:</th>
          <td>{{ currentElement.medicalInfo.pressureUlcerDescription || '' }}</td>
        </tr>
        <tr>
          <th>Apellido del doctor:</th>
          <td>{{ currentElement.medicalInfo.doctorLastName || '' }}</td>
        </tr>
        <tr>
          <th>Nombre del doctor:</th>
          <td>{{ currentElement.medicalInfo.doctorFirstName || '' }}</td>
        </tr>
        <tr>
          <th>Segundo nombre del doctor:</th>
          <td>{{ currentElement.medicalInfo.doctorMiddleName || '' }}</td>
        </tr>
        <tr>
          <th>Certificado en:</th>
          <td>{{ currentElement.medicalInfo.certificateIn || '' }}</td>
        </tr>
        <tr>
          <th>Número de licencia profesional:</th>
          <td>{{ currentElement.medicalInfo.professionalLicenseNumber || '' }}</td>
        </tr>
        <tr>
          <th>Frecuencia de chequeo médico:</th>
          <td>{{ currentElement.medicalInfo.medicalCheckupFrequency || '' }}</td>
        </tr>
        <tr>
          <th>Última inmunización:</th>
          <td>{{ currentElement.medicalInfo.lastImmunization || '' }}</td>
        </tr>
        <tr>
          <th>Vacuna contra COVID-19:</th>
          <td>{{ currentElement.medicalInfo.covid19Vaccine || '' }}</td>
        </tr>
        <tr>
          <th>Enfermedad infecciosa:</th>
          <td>{{ currentElement.medicalInfo.infectiousDisease || '' }}</td>
        </tr>
        <tr>
          <th>Peso:</th>
          <td>{{ currentElement.medicalInfo.weight || '0' }} kg</td>
        </tr>
        <tr>
          <th>Altura:</th>
          <td>{{ currentElement.medicalInfo.height || '0' }} cm</td>
        </tr>
      </table>
      <div v-if="currentElement.laboratoryAnalysis && currentElement.laboratoryAnalysis.length">
        <div class="group-heading mt-5">Análisis de laboratorio</div>
        <table>
          <tr v-for="(analysis, index) in currentElement.laboratoryAnalysis" :key="index">
            <th>Análisis:</th>
            <td>
              <div>
                Tipo: {{ analysis.description || '' }}
              </div>
              <div>
                Fecha: {{ analysis.date || '' }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="currentElement.diagnoses && currentElement.diagnoses.length">
        <div class="group-heading mt-5">Diagnósticos del adulto mayor</div>
        <table>
          <tr v-for="(diagnoses, index) in currentElement.diagnoses" :key="index">
            <th>Diagnóstico:</th>
            <td>
              <div>
                Tipo: {{ diagnoses.description || '' }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="currentElement.medications && currentElement.medications.length">
        <div class="group-heading mt-5">Tratamiento farmacológico</div>
        <table>
          <tr v-for="(medications, index) in currentElement.medications" :key="index">
            <th>Tratamiento:</th>
            <td>
              <div>
                Tipo: {{ medications.description || '' }}
              </div>
              <div>
                Horarios: {{ medications.schedule || '' }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Registro'"
        :type="type"
        :print="print"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>

</template>

<script>
import TopInfoAction from "@/components/TopInfoAction.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import santaLuciaLogo from "@/assets/images/santa-lucia-logo.png"
import customStore from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";

export default {
  name: 'PrintRegister',
  components: {Breadcrumbs, TopInfoAction, ActionPopUp},
  props: {},
  data() {
    return {
      isPrinting: false,
      dialog: false,
      type: '',
      print: false,
      currentElement: {
        personalInfo: {
          firstName: '',
          lastName: '',
          genre: '',
          maritalStatus: '',
          birthday: new Date().toISOString().substr(0, 10),
          address: ''
        },
        legalRepresentative: {
          firstName: null,
          lastName: null,
          age: 0,
          relationship: '',
          address: '',
          phone: '',
          occupation: ''
        },
        careRequirements: {
          mobilityOptions: '',
          dressOptions: '',
          hygieneOptions: '',
          bladderControlOptions: '',
          feedingOptions: ''
        },
        currentCondition: {
          visualLimitation: '',
          emotionalState: '',
          auditoryLimitation: '',
          behavioralDisorders: '',
          mealPreparation: '',
          householdTasks: '',
          orientation: '',
          memoryDisorders: '',
          inconsistenciesCommunication: ''
        },
        medicalInfo: {
          oxygenUsageHoursPerDay: '',
          pressureUlcerDescription: '',
          doctorLastName: '',
          doctorFirstName: '',
          doctorMiddleName: '',
          certificateIn: '',
          professionalLicenseNumber: '',
          medicalCheckupFrequency: '',
          lastImmunization: '',
          covid19Vaccine: '',
          infectiousDisease: '',
          weight: 0,
          height: 0
        },
        laboratoryAnalysis: [],
        diagnoses: [],
        medications: [],
      },
      srcImage: null,
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Registro",
          disabled: false,
          path: `/dashboard/edit/${this.$route.params?.id}`,
        },
        {
          text: "Imprimir Registro",
          disabled: true,
          path: `/print${this.$route.params?.id}`,
        },
      ],
    }
  },
  computed: {
    logo: () => santaLuciaLogo
  },
  async created() {
    await this.fillData()
  },
  methods: {
    async printContract() {
      try {
        const completeName = `${this.currentElement?.personalInfo?.firstName}_${this.currentElement?.personalInfo?.lastName}` || 'archivo'
        await customStore.getPdfRegister(this.$route.params.id, completeName)
        this.print = true
        this.dialog = true
        this.type = 'success'
      } catch {
        this.print = false
        this.dialog = true
        this.type = 'error'
      }
    },
    async fillData() {
      try {
        await this.getPrincipalInformation()
        await this.getCareRequirements()
        await this.getCurrentCondition()
        await this.getMedicalInfo()
        await this.getLaboratoryAnalysis()
        await this.getDiagnoses()
        await this.getMedications()
      } catch {
        this.print = false
        this.type = "error";
        this.dialog = true;
      }
    },
    async getPrincipalInformation() {
      const res = await customStore.getDataCrudByModule(
          'patients',
          this.$route.params.id
      );
      this.currentElement.personalInfo = res
      if (res?.legalRepresentativeId) {
        await this.getLegalRepresentative({legalRepresentativeId: res.legalRepresentativeId})
      }
    },
    async getLegalRepresentative({legalRepresentativeId = null}) {
      const res = await customStore.getDataCrudByModule(
          'legal-representatives',
          legalRepresentativeId,
      );
      this.currentElement.legalRepresentative = res
    },
    async getCareRequirements() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'care-requirements',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        [this.currentElement.careRequirements] = res.data
      }
    },
    async getCurrentCondition() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'current-condition',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        [this.currentElement.currentCondition] = res.data
      }
    },
    async getMedicalInfo() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'medical-infos',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        [this.currentElement.medicalInfo] = res.data
      }
    },
    async getLaboratoryAnalysis() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'laboratory-analysis',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        this.currentElement.laboratoryAnalysis = res.data
      } else {
        this.currentElement.laboratoryAnalysis = []
      }
    },
    async getDiagnoses() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'diagnoses',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        this.currentElement.diagnoses = res.data
      } else {
        this.currentElement.diagnoses = []
      }
    },
    async getMedications() {
      const res = await customStore.getDataCrudByModuleAndParam(
          'medications',
          this.$route.params.id,
          'patientId'
      );
      if (res?.data?.length) {
        this.currentElement.medications = res.data
      } else {
        this.currentElement.medications = []
      }
    },
    closeAction() {
      this.dialog = false;
    },
    cancel() {
      this.$router.push(`/dashboard/edit/${this.$route.params?.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-paper {
  background-color: #fff;
  box-shadow: none;
  padding: 30px;
}

h1 {
  margin-top: 0;
}

.group-heading {
  background-color: #f8f9fa;
  padding: 10px;
  font-weight: bold;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

tr {
  width: 100%;

  td {
    width: 100%;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
  }

  th {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    width: 50%;
    font-weight: bold;
  }
}

.print-button {
  text-align: center;
  margin-top: 20px;
}

</style>
