import axios from "axios";
import {ACCESS_TOKEN} from "@/utils/constants";
import router from "@/router";
import store from "@/store";
import loginManagament from "@/services/userManagement";
import {REFRESH_TOKEN} from "../utils/constants";

export default function setup() {
    axios.interceptors.request.use(
        function (config) {
            let getToken = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
            if (getToken) {
                config.headers.Authorization = `Bearer ${getToken}`;
            }
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            if (error.response?.status === 401) {
                try {
                    let refreshToken = JSON.parse(localStorage.getItem(REFRESH_TOKEN));
                    const respondeRefreshToken = await loginManagament.refreshToken(refreshToken);
                    store.dispatch("auth/refreshToken", respondeRefreshToken, {
                        root: true,
                    });
                    router.push("/");
                    return axios(error.config);
                } catch {
                    store.dispatch(
                        "auth/refreshToken",
                        {
                            access_token: null,
                            refresh_token: null,
                        },
                        {root: true}
                    );
                    router.push("/");
                }
            }
            return Promise.reject(error);
        }
    );
}
