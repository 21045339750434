<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :validBtn="hasAccess('/employees', 'POST')"
        principal-btn-title="Nuevo empleado"
        subtitle="Crea, busca, filtra y visualiza empleados"
        title="Empleados"
        @setDefaultPrimaryAction="createNewEmployee"
    ></top-info-action>
    <employee-resume></employee-resume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "@/components/TopInfoAction.vue";
import EmployeeResume from "@/components/employee/EmployeeResume.vue";
import moduleMixin from "@/mixins/moduleMixin";

export default {
  name: "Employees",
  mixins: [moduleMixin],
  components: {TopInfoAction, Breadcrumbs, EmployeeResume},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Empleados",
        disabled: true,
        path: "/employees",
      },
    ],
  }),
  methods: {
    createNewEmployee() {
      this.$router.push({
        name: "CrudEmployee",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
