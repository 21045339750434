<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        principal-btn-title="Nuevo role"
        subtitle="Crea, busca, filtra y visualiza roles"
        title="Roles"
        @setDefaultPrimaryAction="createNewProduct"
    ></top-info-action>
    <RoleResume></RoleResume>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "@/components/TopInfoAction.vue";
import moduleMixin from "@/mixins/moduleMixin";
import RoleResume from "../components/roles/RoleResume.vue";


export default {
  name: "Roles",
  mixins: [moduleMixin],
  components: {RoleResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Roles",
        disabled: true,
        path: "/role",
      },
    ],
  }),
  methods: {
    createNewProduct() {
      this.$router.push({
        name: "CrudRole",
        params: {crudAction: "create", id:''},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
