import customStore from "@/services/customStore";
import {mapActions, mapState} from "vuex";

export default {
    props: {
        isWizard: {type: Boolean, default: false},
    },
    data() {
        return {
            currentElement: {},
            validForm: false,
            validFormCopy: false,
            loaded: false,
            type: "error",
            dialog: false,
            module: "",
            titleEdit: "",
            titleCreate: "",
            responseElement: {},
            redirectAfterSave:false,
            crudAction:'create',
            pathName:'',
            message:''
        };
    },
    computed: {
        showTitle() {
            let title = "";
            if (this.$route.params?.crudAction) {
                switch (this.$route.params?.crudAction) {
                    case "create":
                        title = this.titleCreate;
                        break;
                    case "edit":
                        title = this.titleEdit;
                        break;
                    default:
                        title = "";
                        break;
                }
            }
            return title;
        },
        ...mapState('contract', ['currentContract', 'currentStep']),
    },
    async mounted() {
        await this.loadData();
        if (this.$route?.params?.id && this.$route?.params?.crudAction === "edit") {
            try {
                this.loaded = false;
                if (this.module) {
                    await this.fillData();
                    await this.beforeFillData();
                }
                this.loaded = true;
            } catch (error) {
                this.type = "error";
                this.dialog = true;
            }
        } else {
            this.loaded = true;
        }
    },
    methods: {
        async fillData() {
            this.currentElement = await customStore.getDataCrudByModule(
                this.module,
                this.$route.params.id
            );
        },
        async save() {
            try {
                if (
                    this.$route.params?.crudAction &&
                    this.$route.params?.crudAction === "create"
                ) {
                    await this.beforeSend();
                    this.responseElement = await customStore.postDataByModule(
                        this.module,
                        this.currentElement
                    );
                    await this.afterSend();
                    this.type = "success";
                    this.dialog = true;
                } else {
                    await this.beforeSend();
                    this.responseElement = await customStore.postDataByModule(
                        this.module,
                        this.currentElement
                    );
                    await this.afterSend();
                    this.type = "success";
                    this.dialog = true;
                }
            } catch (error) {
                this.errorSave()
                this.type = "error";
                this.dialog = true;
                this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
            }
        },
        errorSave() {

        },
        validFormInput() {
        },
        beforeSend() {
        },
        afterSend() {
        },
        cancel() {
            if (this.$route.params.origin === "newContract") {
                this.$router.back();
            } else {
                this.$router.push(`/${this.pathBack}`);
            }
        },
        closeAction() {
            if (this.type !== "error") {
                if (this.$route.params.origin === "newContract") {
                    if (this.module === 'vehicles') {
                        this.currentContract.vehicleId = this.responseElement.uuid;
                        this.currentContract.vehicle = `${this.responseElement.make} ${this.responseElement.model} (Licence Plate: ${this.responseElement.licensePlate})`;
                        this.setCurrentElement(this.currentContract);
                        this.setCurrentStep(3);
                    } else if (this.module === 'clients') {
                        this.currentContract.clientId = this.responseElement.uuid;
                        this.currentContract.client = `${this.responseElement.name} ${this.responseElement.lastName}`;
                        this.setCurrentElement(this.currentContract);
                        this.setCurrentStep(4);
                    }
                    this.$router.back();
                } else {
                    if (this.redirectAfterSave) {
                        this.$router.push({
                            name: this.pathName,
                            params: {crudAction: 'edit', id: this.responseElement.uuid},
                        });
                        this.$router.go(0);
                    }else {
                        this.$router.push(`/${this.pathBack}`);
                    }
                }
            }
            this.dialog = false;
        },
        async loadData() {
        },
        async beforeFillData() {
        },
        ...mapActions('contract', ['setCurrentElement', 'setCurrentStep']),
    },
};
