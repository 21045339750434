<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validForm && hasAccess('/inventory-stores', 'POST')"
        secondary-btn-title="Regresar"
        subtitle="Llena el formulario y presiona el boton guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">

      <InventoryStoreForm
          :store-name="storeName"
          :current-element="currentElement"
          @validFormInput="validFormInput"
          @getInventoryData="getInventoryStoreByStoreId"
      />
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="validForm && hasAccess('/inventory-stores', 'POST')"
          secondary-btn-title="Regresar"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Store'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import customStore from "../services/customStore";
import InventoryStoreForm from "../components/inventoryStore/InventoryStoreForm.vue";
import moduleMixin from "../mixins/moduleMixin";
import storesService from "@/services/storesService";


export default {
  name: "CRUDInventoryStore",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: "inventory-stores"},
  },
  components: {
    InventoryStoreForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Inventario por tienda",
          disabled: false,
          path: "/inventory-stores",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Inventario por tienda`,
          disabled: true,
          path: "/inventory-stores",
        },
      ],
      currentElement: {
        storeId: this.$route?.params?.id,
        inventoryStore: [],
        products: [],
        storeStock: []
      },
      storeName: '',
      module: "",
      titleCreate: "Crear inventario por tienda",
      titleEdit: `Editar inventario por tienda`,
    };
  },
  computed: {
    showTitle() {
      let title = "";
      if (this.$route.params?.crudAction) {
        switch (this.$route.params?.crudAction) {
          case "create":
            title = this.titleCreate;
            break;
          case "edit":
            title = `${this.titleEdit} ${this.storeName || ''}`;
            break;
          default:
            title = "";
            break;
        }
      }
      return title;
    },
  },

  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
    async loadData() {
      await this.getStoreStockByStoreId()
      const {data: products} = await customStore.getDataByModule('products', '', 0, 10000)
      this.currentElement.products = products
      await this.getInventoryStoreByStoreId()
    },
    async getInventoryStoreByStoreId() {
      const newParams = {
        storeId: this.$route?.params?.id
      }
      const {data: inventoryStore} = await customStore.getDataByModuleAndParam(`inventory-stores`, '', 0, 10000, "", "", "", newParams)
      this.currentElement.inventoryStore = inventoryStore
    },
    async getStoreStockByStoreId() {
      const data = {
        storeId: this.$route?.params?.id
      }
      this.currentElement.storeStock = await customStore.getDataByModuleAndParamWithOutPage('store-stocks', data)
      const store = await storesService.getStoreById(data.storeId)
      this.storeName = store.name
    },
    async downloadInventoryFile() {
      const storeIds = []
      storeIds.push(this.$route?.params?.id)
      await customStore.downloadInventory(`inventory-${this.getCurrentDate()}`, storeIds)
    },
    async downloadInventoryOrderFile() {
      const storeIds = []
      storeIds.push(this.$route?.params?.id)
      await customStore.downloadInventoryOrder(`inventory-orders-${this.getCurrentDate()}`, storeIds)
    },
    getCurrentDate() {
      const currentDate = new Date(); // Get the current date and time
      const day = currentDate.getDate().toString().padStart(2, '0'); // Day of the month (with leading zeros if needed)
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month (add 1 because months are zero-indexed)
      const year = currentDate.getFullYear(); // Year

      // Format the date as YYYY-MM-DD (you can adjust the format as per your preference)
      return `${year}-${month}-${day}`;
    }
  },
};
</script>

<style scoped></style>
