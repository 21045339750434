import { render, staticRenderFns } from "./CRUDInventoryStore.vue?vue&type=template&id=e894c124&scoped=true"
import script from "./CRUDInventoryStore.vue?vue&type=script&lang=js"
export * from "./CRUDInventoryStore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e894c124",
  null
  
)

export default component.exports