<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <title-info-card icon="mdi-shield-key" textTitle="Información del role">
              <template #contentCard>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="">
                      <label class="input-label" for="name">Nombre<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.name"
                          id="name"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder=""
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="roleId">
                  <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <section class="list-resources-container">
                      <div class="border-line-resource"> <span>Recursos Disponibles</span> </div>
                      <div class="mt-2 v-text-search-container">
                        <v-text-field
                            prepend-inner-icon="mdi-magnify"
                          v-model="searchAvailableResources"
                            @input="searchValues"
                          id="name"
                          hide-details
                          solo
                          color="backofficeInput"
                          placeholder="Buscar recursos disponibles"
                      />
                      </div>
                      <div class="list-container-resources" v-if="availableResources.length">
                        <v-list dense>
                          <v-list-item v-for="(item, index) in availableResources" :key="index" @click="addResources(item)">
                            <v-list-item-avatar>
                              <v-checkbox :input-value="showMarkedAvailableCheckbox(item)" ></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div>
                                  <b v-if="item.resourceName">{{item.resourceName}}</b>
                                </div>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <div style="display: flex; align-items: center;" v-if="item.permission === 'POST'">
                                  <v-icon size="15">mdi-plus</v-icon>
                                  <span class="ml-1">
                                     {{item.permission}}
                                  </span>

                                </div>
                                <div style="display: flex; align-items: center;" v-if="item.permission === 'GET'">
                                  <v-icon size="15">mdi-format-list-bulleted</v-icon>
                                  <span class="ml-1">
                                     {{item.permission}}
                                  </span>
                                </div>

                                <div style="display: flex; align-items: center;" v-if="item.permission === 'DELETE'">
                                  <v-icon size="15">mdi-delete-empty-outline</v-icon>
                                  <span class="ml-1">
                                     {{item.permission}}
                                  </span>
                                </div>

                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <div  :style="{ backgroundColor: resourceType[item.resourceType] }" class="chip-type ml-3" >
                                {{item.resourceType}}
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div class="no-resource-available mt-3" v-else>
                        <div style="display: flex; align-items: center;">
                          <v-icon size="25">mdi-alert-circle</v-icon>
                          <span class="ml-1">No hay recursos disponibles</span>
                        </div>
                      </div>
                    </section>
                  </v-col>
                  <v-col class="btn-container-move" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <div class="show-mobile" style="cursor: pointer">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" rounded small class="mb-3" :disabled="!selectedAvailableAssignedResources.length" @click="removeResources">
                            <v-icon size="40" >
                              mdi-pan-left
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Remover recursos</span>
                      </v-tooltip>
                    </div>
                    <div class="show-desktop mb-3" style="cursor: pointer">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="assignResources" small rounded :disabled="!selectedAvailableResources.length">
                            <v-icon size="25">
                              mdi-arrow-down
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Asignar recursos</span>
                      </v-tooltip>
                    </div>
                    <div class="show-mobile" >
                      <v-icon  size="30" >mdi-arrow-split-vertical</v-icon>
                    </div>
                    <div class="show-mobile" style="cursor: pointer">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn small rounded class="mt-3" v-on="on" @click="assignResources" :disabled="!selectedAvailableResources.length">
                            <v-icon size="40">
                              mdi-pan-right
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Asignar recursos</span>
                      </v-tooltip>
                    </div>
                    <div class="show-desktop mt-3" style="cursor: pointer">
                      <v-tooltip  top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" small rounded :disabled="!selectedAvailableAssignedResources.length" @click="removeResources">
                            <v-icon size="25">
                              mdi-arrow-up
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Regresar recursos</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <section class="list-resources-container" >
                      <div class="border-line-resource-primary"> <span>Recursos Asignados</span></div>
                      <div class="mt-2 v-text-search-container">
                        <v-text-field
                            prepend-inner-icon="mdi-magnify"
                            v-model="searchAvailableAssign"
                            id="name"
                            @input="searchValuesAssigned"
                            hide-details
                            solo
                            color="backofficeInput"
                            placeholder="Buscar recursos asignados"
                        />
                      </div>
                      <div class="list-container-resources" v-if="availableasignedResources.length">
                        <v-list dense>
                          <v-list-item v-for="(item, index) in availableasignedResources" :key="index" @click="addRemoveAssigned(item)">
                            <v-list-item-avatar>
                              <v-checkbox  :input-value="showMarkedAvailableAssignedCheckbox(item)" ></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div>
                                  <b v-if="item.resource.resourceName">{{item.resource.resourceName}}</b>
                                </div>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <div style="display: flex; align-items: center;" v-if="item.resource.permission === 'POST'">
                                  <v-icon size="15">mdi-plus</v-icon>
                                  <span class="ml-1">
                                     {{item.resource.permission}}
                                  </span>

                                </div>
                                <div style="display: flex; align-items: center;" v-if="item.resource.permission === 'GET'">
                                  <v-icon size="15">mdi-format-list-bulleted</v-icon>
                                  <span class="ml-1">
                                     {{item.resource.permission}}
                                  </span>
                                </div>

                                <div style="display: flex; align-items: center;" v-if="item.resource.permission === 'DELETE'">
                                  <v-icon size="15">mdi-delete-empty-outline</v-icon>
                                  <span class="ml-1">
                                     {{item.resource.permission}}
                                  </span>
                                </div>

                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <div  :style="{ backgroundColor: resourceType[item.resource.resourceType] }" class="chip-type ml-3" >
                                {{item.resource.resourceType}}
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div class="no-resource-available mt-3" v-else>
                        <div style="display: flex; align-items: center;">
                          <v-icon size="25">mdi-alert-circle</v-icon>
                          <span class="ml-1">No hay recursos asignados disponibles</span>
                        </div>
                      </div>
                    </section>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";
import customStore from "../../services/customStore";

export default {
  name: "RoleForm",
  components: {TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    resources: {
      type: Array,
      default: () => []
    },
    assignedResource: {
      type: Array,
      default: () => []
    },
    roleId: {
      type:String,
      default: ""
    }
  },
  data() {
    return {
      timeout:6000,
      text:'',
      snackbar:false,
      required,
      validForm: false,
      searchAvailableResources: '',
      searchAvailableAssign:'',
      availableResources:[],
      availableasignedResources:[],
      httpColors:{
        'GET': '#1a8dff',
        'POST': '#00c431',
        'DELETE': '#ff2c2c',
        'HEAD': '#ff2c2c'
      },
      resourceType:{
        'view': '#808080',
        'endpoint': '#008080',
      },
      selectedAvailableResources:[],
      selectedAvailableAssignedResources:[]
    }
  },
  mounted() {
    this.availableResources = this.resources
    this.availableasignedResources = this.assignedResource
  },
  watch:{
    'resources':{
      handler() {
        this.assignValues()
      }, deep: true
    }
  },

  methods: {
    assignValues(){
      this.availableResources = this.resources
      this.availableasignedResources = this.assignedResource
    },
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    addResources(evt) {
      const findAvailableResource = this.selectedAvailableResources.findIndex((item)=> item.uuid === evt.uuid)
      if (findAvailableResource === -1) {
        this.selectedAvailableResources.push(evt)
      }else {
        this.selectedAvailableResources.splice(findAvailableResource, 1)
      }
    },
    searchValues(evt){
      const copyResources = [...this.resources]
      const searchTerm = evt.toLowerCase();
      if (!searchTerm) {
        this.availableResources = this.resources;
      } else {
        this.availableResources = copyResources.filter(item => {
          return item?.resourceName.toLowerCase().includes(searchTerm) || item.permission.toLowerCase().includes(searchTerm) || item.resourceType.toLowerCase().includes(searchTerm);
        });
      }
    },
    showMarkedAvailableCheckbox(resource) {
      if (this.availableResources.length) {
        const findInSelectedAvailableResources = this.selectedAvailableResources.find(item=> item.uuid === resource.uuid)
        return !!findInSelectedAvailableResources?.uuid;
      }
     return false
    },
    searchValuesAssigned(evt){
      const copyResources = [...this.assignedResource]
      const searchTerm = evt.toLowerCase();
      if (!searchTerm) {
          this.availableasignedResources = this.assignedResource;
      } else {
        this.availableasignedResources = copyResources.filter(item => {
          return item?.resource.resourceName.toLowerCase().includes(searchTerm) || item.resource.permission.toLowerCase().includes(searchTerm) || item.resource.resourceType.toLowerCase().includes(searchTerm);
        });
      }
    },
   async assignResources() {
      try {
        let promises = []
        for(let i=0; i<this.selectedAvailableResources.length; i++) {
          const data = {
            resourceId: this.selectedAvailableResources[i].uuid,
            roleId: this.roleId
          }
          promises.push(customStore.postDataByModule('role/resource', data))
        }
        await Promise.all(promises)
        this.selectedAvailableResources = []
        this.snackbar = true
        this.text = 'Roles agregados correctamente'
        this.$emit('reloadResources')
      }catch(error){
        this.snackbar = true
        this.text = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
      }
    },
    async removeResources() {
      try {
        let promises = []
        for(let i=0; i<this.selectedAvailableAssignedResources.length; i++) {
          promises.push(customStore.deleteByModule('role/resource', this.selectedAvailableAssignedResources[i]))
        }
        await Promise.all(promises)
        this.selectedAvailableAssignedResources = []
        this.snackbar = true
        this.text = 'Roles removidos correctamente'
        this.$emit('reloadResources')
      }catch(error){
        this.snackbar = true
        this.text = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
      }
    },
    addRemoveAssigned(evt) {
      const findAvailableAssignedResource = this.selectedAvailableAssignedResources.findIndex((item)=> item.uuid === evt.uuid)
      if (findAvailableAssignedResource === -1) {
        this.selectedAvailableAssignedResources.push(evt)
      }else {
        this.selectedAvailableAssignedResources.splice(findAvailableAssignedResource, 1)
      }
    },
    showMarkedAvailableAssignedCheckbox(resource) {
      if (this.availableasignedResources.length) {
        const findInSelectedAvailableAssignedResources = this.selectedAvailableAssignedResources.find(item=> item.uuid === resource.uuid)
        return !!findInSelectedAvailableAssignedResources?.uuid;
      }
      return false
    }
  }
}
</script>

<style>
  .container-txt-resource {
    font-size: 16px;
    text-align: center;
  }
  .list-resources-container {
    background-color: var(--v-containerResource-base);
    min-height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .border-line-resource {
      display: flex;
      justify-content: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: var(--v-appBarInitial-base);
      border: 1px solid var(--v-appBarInitial-base);
      padding: 12px;
      span {
        text-align: center;
        color: white;
      }
    }

    .border-line-resource-primary {
      display: flex;
      justify-content: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
      padding: 12px;
      span {
        text-align: center;
        color: white;
      }
    }
  }
  .btn-container-move {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .v-text-search-container {
    padding: 10px;
    .v-input__slot {
      box-shadow: none !important;
    }
  }
  .list-container-resources {
    margin-top: 5px;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 10px;
  }

  .list-container-resources::-webkit-scrollbar {
    width: 3px;
  }

  .list-container-resources::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .list-container-resources::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: 3px solid transparent;
  }

  .list-container-resources::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .no-resource-available {
    display: flex;
    justify-content: center;
    span {
      font-size: 15px;
      text-align: center;
    }
  }

  .chip-type {
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 8px;
    color: white;
    font-size: 10px;
    background-color: red;
  }

</style>
