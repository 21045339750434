import loginManagament from "@/services/userManagement";
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    RESOURCES,
    USER,
} from "@/utils/constants";
import employeeService from "@/services/employeeService";

export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
        refresh_token: null,
        resources: [],
    },
    mutations: {
        SET_TOKEN: (state, payload) => {
            localStorage.setItem(ACCESS_TOKEN, JSON.stringify(payload));
            state.token = payload;
        },
        SET_REFRESH_TOKEN: (state, payload) => {
            localStorage.setItem(REFRESH_TOKEN, JSON.stringify(payload));
            state.refresh_token = payload;
        },
        SET_RESOURCES: (state, payload) => {
            localStorage.setItem(RESOURCES, JSON.stringify(payload));
            state.resources = payload;
        },
        SET_USERID: (state, payload) => {
            localStorage.setItem(USER, JSON.stringify(payload));
            state.user = payload;
        },
        CLEAR_TOKEN: (state) => {
            localStorage.clear();
            state.token = null;
            state.refresh_token = null;
            state.user = null;
            state.resources = [];
        },
    },
    actions: {
        async fetchUser({commit}, payload) {
            try {
                const {access_token, refresh_token, resources} =
                    await loginManagament.login(payload);
                commit("SET_TOKEN", access_token);
                commit("SET_REFRESH_TOKEN", refresh_token);
                commit("SET_RESOURCES", resources);
                const userIdData = await employeeService.getMeById();
                commit("SET_USERID", userIdData);
                return {
                    message: "success",
                    status: "success",
                };
            } catch (e) {
                return {
                    message: e,
                    status: "failed",
                };
            }
        },
        async updateDataUser({commit}) {
            const userIdData = await employeeService.getMeById();
            commit("SET_USERID", userIdData);
        },
        refreshToken({commit}, payload) {
            commit("SET_TOKEN", payload?.access_token);
            commit("SET_REFRESH_TOKEN", payload?.refresh_token);
        },
    },
    getters: {
        getResources(state) {
            let resources = JSON.parse(localStorage.getItem(RESOURCES));
            state.resources = resources
            return state.resources
        },
        getUser(state) {
            let user = JSON.parse(localStorage.getItem(USER));
            state.user = user
            return state.user
        }
    },
};
