<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="9" md="9" sm="6" xl="9" xs="12">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          />
        </v-col>
        <v-col v-if="hasAccess('/patients/download', 'GET')"  cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
          <download-btn module="/patients/download" titleBtn="Pacientes" fileDownloadName="Pacientes"></download-btn>
        </v-col>
      </v-row>
      <v-data-table
          :expanded.sync="expanded"
          :headers="headers"
          :items="dataSource"
          :loading="loaded"
          :options.sync="options"
          :mobile-breakpoint="200"
          :search="search"
          :server-items-length="totalItems"
          :single-expand="singleExpand"
          class="mt-5"
          item-key="uuid"
          hide-default-header
      >
        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value" @click="handleHeaderClick(header)">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <!--        <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="bg-expanded-row">
                    <main class="detail-container-row">
                      <v-row no-gutters>
                        <v-col cols="12" lg="5" md="5" sm="5" xl="5">
                          <div>
                            <div class="display-align-center principal-detail-row">
                              <v-icon color="tableHeader">mdi-face-man-outline</v-icon>
                              <span class="ml-1">Información General</span>
                            </div>
                          </div>
                          <div class="px-6 mt-3">
                            <v-row class="info-element-text" no-gutters>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="4"
                                  md="12"
                                  sm="12"
                                  xl="4"
                              >
                                <span>Nombre</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="8"
                                  md="12"
                                  sm="12"
                                  xl="8"
                              >
                                <span>{{ item.firstName || "Sin Información" }} {{ item.lastName || "Sin Información" }}</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="4"
                                  md="12"
                                  sm="12"
                                  xl="4"
                              >
                                <span>Dirección</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="8"
                                  md="12"
                                  sm="12"
                                  xl="8"
                              >
                                <span>{{
                                    item.address || "Sin Información"
                                  }}</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="4"
                                  md="12"
                                  sm="12"
                                  xl="4"
                              >
                                <span>Genero</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="8"
                                  md="12"
                                  sm="12"
                                  xl="8"
                              >
                                <span>{{ item.genre || "Sin Información" }}</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="4"
                                  md="12"
                                  sm="12"
                                  xl="4"
                              >
                                <span>Estado civil</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="8"
                                  md="12"
                                  sm="12"
                                  xl="8"
                              >
                                <span>{{ item.maritalStatus || "Sin Información" }}</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="4"
                                  md="12"
                                  sm="12"
                                  xl="4"
                              >
                                <span>Cumpleaños</span>
                              </v-col>
                              <v-col
                                  class="mt-2"
                                  cols="12"
                                  lg="8"
                                  md="12"
                                  sm="12"
                                  xl="8"
                              >
                                <span>{{ item.birthday || "Sin Información" }}</span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </main>
                  </td>
                </template>-->
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
                      isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
                    "
          >
            <td>
              <span>{{ formatTime(item.createdAt) }}</span>
            </td>
            <td>
              <span>{{ item.firstName }}</span>
            </td>
            <td>
              <span>{{ item.lastName }}</span>
            </td>
            <td>
              <span>{{ item.genre }}</span>
            </td>
            <td>
              <span>{{ item.maritalStatus }}</span>
            </td>
            <td>
              <span>{{ item.birthday }}</span>
            </td>
            <td>
              <span>{{ item.address }}</span>
            </td>
            <td>
              <div class="display-align-center">
                <v-icon
                    v-if="hasAccess('/patients', 'POST')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudRegister', 'edit')"
                >mdi-pencil-outline
                </v-icon>
                <v-icon
                    v-else-if="hasAccess('/patients', 'GET')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudRegister', 'edit')"
                >mdi-magnify
                </v-icon>
                <v-icon
                    v-if="hasAccess('/patients', 'DELETE')"
                    class="ml-2 cursor-pointer-action"
                    color="tableActions"
                    @click="deleteRow(item, 'patients', 'delete')"
                >mdi-trash-can-outline
                </v-icon>
              </div>
            </td>
            <!--            <td @click="expand(!isExpanded)">
                          <v-icon v-if="isExpanded" class="cursor-pointer-action"
                          >mdi-chevron-up
                          </v-icon>
                          <v-icon v-else class="cursor-pointer-action"
                          >mdi-chevron-down
                          </v-icon>
                        </td>-->
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Registro'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import ActionPopUp from "@/components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import DownloadBtn from "../downloadBtn.vue";
import moment from "moment";

export default {
  name: "registerResume",
  components: {DownloadBtn, ActionPopUp},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "patients",
    };
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Fecha de creación",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Nombre",
          value: "firstName",
          sortable: true,
        },
        {
          text: "Apellidos",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Genero",
          sortable: false,
          value: "genre",
        },
        {
          text: "Estado civil",
          sortable: false,
          value: "maritalStatus",
        },
        {
          text: "Fecha de nacimiento",
          sortable: false,
          value: "birthday",
        },
        {
          text: "Dirección",
          sortable: false,
          value: "address",
        },
        {text: "", value: "actions"},
      ];
    },
    formatTime(time) {
      if (!time) return "N/A"
      return moment(time).format("YYYY-MM-DD")
    },
  },
};
</script>

<style scoped>
.personal-information-text > .name {
  font-family: "Montserrat-Regular";
  font-size: 17px;
}

.personal-information-text > .subtitle {
  font-family: "Montserrat-Regular";
  font-size: 14px;
}
</style>
