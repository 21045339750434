<template>
    <v-row class="mt-10 mb-10" no-gutters>
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
            <title-info-card icon="mdi-store-outline" textTitle="Sucursal">
                <template #contentCard>
                    <v-row class="mt-2">
                        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                            <select-cards
                                    :available-items="availableItems"
                                    :available-option="availableStores"
                                    :is-one-element="isOneElement"
                                    :isArrayObject="true"
                                    :multiple-select="multipleSelect"
                                    :one-element-string="multipleSelect ? null : storeId"
                                    info="Selecciona una sucursal"
                                    isRequired
                                    property-name="name"
                                    @selectedItem="selectedStore"
                            ></select-cards>
                        </v-col>
                    </v-row>
                </template>
            </title-info-card>
        </v-col>
    </v-row>
</template>

<script>
import SelectCards from "@/components/SelectCard.vue";
import TitleInfoCard from "@/components/TitleInfoCard.vue";
import customStoreService from "@/services/customStore";

export default {
    name: "StoreSelect",
    components: {SelectCards, TitleInfoCard},
    props: {
        storeId: {
            type: String,
            default: "",
        },
        multipleSelect: {
            type: Boolean,
            default: false
        },
        availableItems: {
            type: Array,
            default: () => [],
        },
        isOneElement: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            availableStores: [],
        };
    },
    async mounted() {
        try {
            const result = await customStoreService.getDataByModule(
                "stores",
                "",
                1,
                100
            );
            this.availableStores = result.data;
        } catch (e) {
            console.log(e);
        }
    },
    methods: {
        selectedStore(e) {
            this.$emit("selectedStore", e);
        },
    },
};
</script>
