<template>
  <v-container>
    <v-row class="panel-container mt-5">
      <v-col class="display-logo-panel" cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
           <v-img width="auto" contain :src="santaLuciaLogo"/>
        <div class="principal-title-p mt-2">
          <span>Sistema de Gestión Integral para Estancias del Adulto Mayor</span>
        </div>
        <div class="mt-2">
          <span>Bienvenido {{getUser.name}}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import santaBO from '@/assets/images/Backoffice-Logo-Black.png'
import {mapGetters} from "vuex";
import employeeService from "../services/employeeService";
export default {
  name: "Panel",

  computed:{
    santaLuciaLogo() {
      return santaBO
    },
    ...mapGetters("auth", ["getUser"])
  },
  async created() {
    try {
      await employeeService.getMeById();
    }catch{
      //do nothing
    }
  }
}
</script>

<style scoped lang="scss">
.display-logo-panel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.principal-title-p {
  display: flex;
  span {
    text-align: center;
    font-size: 30px;
  }
}
</style>