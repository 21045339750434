<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="$route.params.origin === 'newContract' ? breadcrumbsItemsContract :breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="true"
        title="Perfil"
        :valid-btn="validForm"
        principal-btn-title="SAVE"
        secondary-btn-title="CANCEL"
        subtitle="Datos del usuario"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <v-row class="mt-4">
        <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
          <v-form v-model="validForm">
            <v-row>
              <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                <title-info-card
                    icon="mdi-face-man-outline"
                    textTitle="Información personal"
                >
                  <template #contentCard>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
                        <div>
                          <label class="input-label" for="Name*"
                          >Nombre<span class="required-field-form">*</span></label
                          >
                          <v-text-field
                              id="name"
                              v-model="currentElement.name"
                              :rules="required"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: Juan"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
                        <div>
                          <label class="input-label" for="Last Name*"
                          >Apellidos<span class="required-field-form"
                          >*</span
                          ></label
                          >
                          <v-text-field
                              id="lastName"
                              v-model="currentElement.lastName"
                              :rules="required"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: Hernandez"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                        <div>
                          <label class="input-label" for="email*"
                          >E-mail<span class="required-field-form">*</span></label
                          >
                          <v-text-field
                              id="email"
                              v-model="currentElement.email"
                              :rules="required"
                              color="backofficeInput"
                              disabled
                              hide-details
                              outlined
                              placeholder="Ejemplo: jhon.s@backoffice.com"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="7" md="7" sm="12" xl="7" xs="12">
                        <div>
                          <label class="input-label" for="Phone*"
                          >Teléfono<span class="required-field-form">*</span></label
                          >
                          <v-text-field
                              id="licensePlate"
                              v-model="currentElement.phone"
                              :rules="required"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: +5425512345678"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="5" md="5" sm="12" xl="5" xs="12">
                        <div>
                          <v-menu
                              ref="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              min-width="290px"
                              offset-y
                              transition="scale-transition"
                          >
                            <template v-slot:activator="{ on }">
                              <label class="input-label" for="Birthday"
                              >Año de nacimiento<span class="required-field-form"
                              >*</span
                              ></label
                              >
                              <v-text-field
                                  id="Birthday"
                                  :rules="birthday"
                                  :value="currentElement.birthday"
                                  hide-details
                                  outlined
                                  placeholder="Ejemplo: 1995-07-26"
                                  readonly
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="currentElement.birthday"
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="menu1 = false"
                              >Cancelar
                              </v-btn>
                              <v-btn
                                  color="primary"
                                  text
                                  @click="$refs.menu.save(date)"
                              >OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col
                          cols="12"
                          lg="12"
                          md="12"
                          sm="12"
                          xl="12"
                          xs="12"
                      >

                        <v-dialog
                            v-model="changePasswordModal"
                            max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                rounded
                                class="btn-active v-btn-primary"
                                v-bind="attrs"
                                v-on="on"
                            >
                              Cambiar contraseña
                            </v-btn>
                          </template>
                          <v-form v-model="validForm">
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Cambiar contraseña</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col>
                                      <div>
                                        <label class="input-label" for="Password*"
                                        >Contraseña
                                          <span class="required-field-form"
                                          >*</span
                                          ></label
                                        >
                                        <v-text-field
                                            id="Password"
                                            v-model="password"
                                            :rules="passRules()"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showPassword ? 'text' : 'password'"
                                            name="input-10-1"
                                            counter
                                            @click:append="showPassword = !showPassword"
                                            color="backofficeInput"
                                            outlined
                                            placeholder="Ejemplo: 123@qwerty!"
                                        />
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <div>
                                        <label class="input-label" for="Password*"
                                        >Confirmar Contraseña
                                          <span class="required-field-form"
                                          >*</span
                                          ></label
                                        >
                                        <v-text-field
                                            id="ConfirmPassword"
                                            v-model="confirmPassword"
                                            :rules="passConfirmRules()"
                                            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showConfirmPassword ? 'text' : 'password'"
                                            name="input-10-1"
                                            counter
                                            @click:append="showConfirmPassword = !showConfirmPassword"
                                            color="backofficeInput"
                                            outlined
                                            placeholder="Ejemplo: 123@qwerty!"
                                        />
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="changePasswordModal = false"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn
                                    @click="changePassword"
                                    :disabled="!validForm"
                                    color="blue darken-1"
                                    text
                                >
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-form>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </template>
                </title-info-card>
              </v-col>
            </v-row>

            <v-row class="mt-10" no-gutters>
              <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                <title-info-card icon="mdi-home-outline" textTitle="Dirección">
                  <template #contentCard>
                    <v-row class="mt-2">
                      <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                        <div>
                          <label class="input-label" for="Street">Calle </label>
                          <v-text-field
                              id="Street"
                              v-model="currentElement.address.street"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: Siempre viva #24, Doctores"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
                        <div>
                          <label class="input-label" for="City">Ciudad </label>
                          <v-text-field
                              id="City"
                              v-model="currentElement.address.city"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: California"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
                        <div>
                          <label class="input-label" for="State">Estado</label>
                          <v-text-field
                              id="State"
                              v-model="currentElement.address.state"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: Jalisco"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12" xl="6" xs="12">
                        <div>
                          <label class="input-label" for="Zip Code">Código postal</label>
                          <v-text-field
                              id="Zip Code"
                              v-model="currentElement.address.zipCode"
                              color="backofficeInput"
                              hide-details
                              outlined
                              placeholder="Ejemplo: 58200"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </title-info-card>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <BottomActions
            :show-secondary-btn="true"
            :valid-btn="validForm"
            principal-btn-title="SAVE"
            secondary-btn-title="CANCEL"
            @setDefaultPrimaryAction="save"
            @setDefaultSecondaryAction="cancel"
        />
      </v-row>
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Client'"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import employeeService from "@/services/employeeService";
import TitleInfoCard from "@/components/TitleInfoCard.vue";
import {required, birthday, passRules} from "@/utils/validationRule";
import TopInfoAction from "@/components/TopInfoAction.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import BottomActions from "@/components/BottomActions.vue";
import {mapActions} from "vuex";

export default {
  name: "Profile",
  mixins: [CRUDMixin],
  props: {
    pathBack: {type: String, default: "dashboard"},
  },
  components: {ActionPopUp, SkeletonLoaded, Breadcrumbs, TopInfoAction, TitleInfoCard, BottomActions},
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Clients",
          disabled: false,
          path: "/clients",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "New"
                  : this.$route.params.crudAction === "edit"
                      ? "Edit"
                      : this.$route.params.crudAction) || ""
          }  Perfil`,
          disabled: true,
          path: "/clients",
        },
      ],

      breadcrumbsItemsContract: [
        {
          text: 'Home',
          disabled: false,
          path: '/dashboard',
        },
        {
          text: 'Perfil',
          disabled: false,
          path: '/profile',
        },
      ],
      module: "authorization/me",
      validForm: false,
      birthday,
      required,
      currentElement: {
        address: {}
      },
      password: '',
      confirmPassword: '',
      newPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      changePasswordModal: false
    };
  },
  methods: {
    ...mapActions("auth", ["updateDataUser"]),
    async loadData() {
      try {
        this.currentElement = await employeeService.getMeById();
      } catch (error) {
        console.error('Error al cargar el perfil del usuario:', error);
      }
    },
    passRules() {
      return passRules
    },
    passConfirmRules() {
      const confirmPasswordRules = [...passRules]
      const confirmPasswordRule = (v) => v === this.password || "Las contraseñas no son iguales"
      confirmPasswordRules.push(confirmPasswordRule)
      return confirmPasswordRules
    },
    async changePassword() {
      try {
        const data = {
          password: this.password,
        }
        await employeeService.updatePassword(data);
        this.password = ""
        this.confirmPassword = ""
        this.changePasswordModal = false
        this.type = "success";
        this.dialog = true;
      } catch (error) {
        console.error('Error al cargar el perfil del usuario:', error);
      }
    },
    async afterSend() {
      await this.updateDataUser();
    },
  }
};
</script>

<style scoped>
/* Estilos CSS específicos para este componente */
</style>
